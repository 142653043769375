/**
 * Created by dwiargo on 2/21/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authService from '../../services/authService';
import httpService from '../../services/httpService';
import navService from '../../services/navService';
import locationService from '../../services/locationService';
import LoaderInfo from '../LoaderInfo/LoaderInfo';
import queryString from 'query-string';
import axios from 'axios';

import * as authActions from '../../redux/actions/authActions';

let hasInit = false;

const onError = (error, callback) => {
  let {status, statusText, data } = error.response;
  navService.redirectTo('/error?'+queryString.stringify({
      status:status,
      statusText:statusText,
      errorMessage: data.errorMessage || data.error_description || data.error || data
    }));
  callback();
}

const getNewAccessToken = (props, refreshToken, callback) => {
  let { url } = props;
  axios.get(`${url.refreshToken.replace(':refreshToken', refreshToken)}`).then((response) => {
    setTimeout(callback);
  }, (error) => {
    onError(error, callback)
  })
}

const init = (props, callback) => {
  let { url, credentials } = props;
  const defaultUri = credentials.redirect_uri;

  let accessToken = authService.getAccessToken();
  if(accessToken === undefined || accessToken === null){
    let currentPath = window.location.href;
    let parsedUrl = queryString.parseUrl(currentPath);
    if(parsedUrl.query.code){
      let newQuery = JSON.parse(JSON.stringify(parsedUrl.query));
      delete newQuery.code;
      delete newQuery.state;

      parsedUrl.query.redirect_uri = defaultUri || parsedUrl.url + (Object.keys(newQuery).length > 0 ? ('?'+ queryString.stringify(newQuery)) : '');
      parsedUrl.query.redirect_uri = window.btoa(parsedUrl.query.redirect_uri);

      axios.post(url.exchangeToken, parsedUrl.query).then(response => {
        let currentUrl = queryString.parseUrl(window.location.pathname);
        navService.redirectTo(currentUrl.url);
        callback();
      }).catch((error) => {
        onError(error, callback);
      })
    } else {
      const _redirectUri = window.btoa(defaultUri || currentPath);
      const refreshToken = authService.getRefreshToken();

      if(refreshToken){
        // let data = parsedUrl.query;
        // data.refresh_token = refreshToken;
        getNewAccessToken(props, refreshToken, callback)
      } else {
        httpService.get({
          url:`${url.login}?redirect_uri=${_redirectUri}`
        }).then(response => {
          window.open(response.data, '_self');
        })
      }
    }
  } else {
    callback();
  }
};

const setHttpInterceptors = (props) => {
  const setHeaders = (config) => {
    let accessToken = authService.getAccessToken();
    config.headers = {
      Authorization: 'Bearer ' + accessToken
    };
  }

  httpService.setInterceptors((config) => {
    return new Promise((resolve, reject) => {
      let accessToken = authService.getAccessToken();
      let refreshToken = authService.getRefreshToken();
      
      if(!accessToken && refreshToken){
        getNewAccessToken(props, refreshToken, () => {
          setHeaders(config);
          resolve(config);
        })
      } else {
        setHeaders(config);
        resolve(config);
      }
    })
  });
};

const setHttpErrorHandler = () => {
  httpService.setErrorHandler( err => {
    err.statusCode = err.response ? (err.response.statusCode || err.response.status) : (err.statusCode || err.status);
    if(err.response) {
      err.response.status = err.response.status || err.response.statusCode;
      const responseData = err.response.data;
      err.response.message = responseData ? (responseData.errorMessage || responseData.error_description || responseData.message || responseData.error) : err.message;

      if (Number(err.statusCode) >= 500) {
        locationService.errorPage(err);
      } else {
        let isBreak = false;
        if(err.response.headers) {
          let authenticateErr = err.response.headers['www-authenticate'] || err.response.headers['WWW-Authenticate'];
          if(authenticateErr) {
            authenticateErr = authenticateErr.replace(/,/g, '&').replace(/"/g, '');
            let parsed = queryString.parse(authenticateErr);
            err.response.message = parsed.error_description || parsed.error;
            err.response.statusText = err.response.status === 401 ? 'Unauthorized' : 'Bad Request';
            locationService.errorPage(err);
            isBreak = true;
          }
        }
        
        if(!isBreak){
          if(err.statusCode === 401){
            if(authService.getRefreshToken()){
              //location.reload(true);
              locationService.errorPage({
                status:401,
                statusText:'Unauthorized'
              })
            } else {
              authService.clearCookie();
              locationService.errorPage({
                status:401,
                statusText:'Unauthorized'
              })
            }
          // } else if(err.response.status === 404){
          //   locationService.errorPage({
          //     status:404,
          //     statusText:'Not Found'
          //   })
          } else {
            return err;
          }
        }
      }
    } else {
      locationService.errorPage({
        status:500,
        message:'Network Error'
      })
    }
  });
};

const setLogoutAction = (props) => {
  let { host, credentials } = props;
  credentials.redirect_uri = window.btoa(credentials.redirect_uri);
  credentials.access_token=authService.getAccessToken();
  authService.setLogoutAction((callback) => {
    callback();
    window.open(`${host}/auth/oauth/logout?${queryString.stringify(credentials)}`, '_self');
  })
};

const getMe = (props) => {
  httpService.get({
    url:`${props.url.me}`
  }).then(response => {
    props.authActions.setProperties({
      user:response.data,
      isLoggedIn:true
    })
    let { onUserLoaded } = props;
    if(onUserLoaded) onUserLoaded(response.data);
  }, locationService.errorPage)
};

class IAMConnect extends Component{
  constructor(){
    super();
    this.state = {
      onProgress:true
    }
  }

  componentWillReceiveProps(props){
    if(props.ready && !hasInit) {
      hasInit = true;
      init(props, () => {
        setHttpInterceptors(props);
        setHttpErrorHandler(props);
        setLogoutAction(props);
        getMe(props);
        this.setState({
          onProgress: false
        })
      })
    }
  }

  render(){
    return(
      <div className="mpk-full width height">
        { this.state.onProgress ? (
          <LoaderInfo statusText="loading account information.."/>
        ):(
          this.props.children
        )}
      </div>
    )
  }
}

IAMConnect.propTypes = {
  host:PropTypes.string.isRequired,
  url:PropTypes.shape({
    exchangeToken:PropTypes.string.isRequired,
    refreshToken:PropTypes.string.isRequired,
    login:PropTypes.string.isRequired,
    me:PropTypes.string.isRequired
  }),
  credentials:PropTypes.shape({
    client_id:PropTypes.string.isRequired,
    state:PropTypes.string.isRequired,
    redirect_uri:PropTypes.string
  }),
  ready:PropTypes.bool,
  onUserLoaded:PropTypes.func
};

const mapStateToProps = state => ({
  auth:state.auth
})

const mapDispatchToProps = dispatch => ({
  authActions:bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(IAMConnect);

