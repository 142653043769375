/**
 * Created by dwiargo on 12/8/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContainer, Button } from 'react-md';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';

class DialogAlert extends Component{
  render(){
    let {title, message, translate, visible, onCancel, children, ...otherProps} = this.props;
    return(
      <DialogContainer
        id="mpk-dialog-alert"
        className="mpk-dialog"
        onHide={() => {}}
        aria-label="mpk-dialog-alert"
        title={translate ? counterpart.translate(title) : title}
        visible={visible}
        {...otherProps}
        actions={[
          <Button flat secondary onClick={() => onCancel()}>
            Ok
          </Button>
        ]}
      >
        <div className="body-text">{ translate ? (<Translate content={message}/>) : (message)}</div>
        {children}
      </DialogContainer>
    )
  }
}

DialogAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  translate: PropTypes.bool,
  onCancel:PropTypes.func.isRequired
};

export default DialogAlert;