/**
 * Created by dwiargo on 2/22/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from 'react-md';

class LoaderInfo extends Component{
  render(){
    let { className='' } = this.props;
    return(
      <div 
        className={`mpk-layout align-center mpk-padding-N all ${className}`}
        style={{textAlign:'left !important'}}>
        <div className="flex-none mpk-margin-N left right">
          <CircularProgress
            id="loader-info"
            scale={.8}
            />
        </div>
        <div 
          className="flex mpk-font-color-D2 mpk-align-left">
          {this.props.statusText || this.props.text}
        </div>
      </div>
    )
  }
}

LoaderInfo.defaultProps = {
  text: 'loading data...'
}

LoaderInfo.propTypes = {
  statusText:PropTypes.string,
  text:PropTypes.string
}

export default LoaderInfo;