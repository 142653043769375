import React from 'react';
import { Wrapper, rdxConnect } from 'libs/react-mpk';
import { Paper } from 'react-md';
import t from 'counterpart';

class Profile extends React.Component{
  render(){
    let { userBalanceAccount } = this.props.tmpObject;

    return(
      <div className="mpk-padding-N padding-top padding-bottom">
        <Paper 
          className="mpk-padding-N padding-all mpk-center-container mpk-animation slide-in"
          style={{
            maxWidth: 640
          }}
        >
          <Wrapper.Form
            contentOnly={true}
            definitions={[
              {
                inputType: 'divider',
                label: t.translate('column.userInfo')
              },
              {
                inputType: 'input',
                label: t.translate('column.username'),
                getValue: d => (userBalanceAccount.userInfo.username),
                readOnly: true
              },
              {
                inputType: 'input',
                label: t.translate('column.name'),
                getValue: d => (userBalanceAccount.userInfo.name),
                readOnly: true
              },
              {
                inputType: 'input',
                label: t.translate('column.email'),
                getValue: d => (userBalanceAccount.userInfo.email),
                readOnly: true
              },
              {
                inputType: 'divider',
                label: t.translate('column.accountInfo')
              },
              {
                inputType: 'input',
                label: t.translate('column.username'),
                getValue: d => (userBalanceAccount.accountInfo.username),
                readOnly: true
              },
              {
                inputType: 'input',
                label: t.translate('column.accountNo'),
                getValue: d => (userBalanceAccount.accountInfo.accountNo),
                readOnly: true
              },
            ]}
          />
        </Paper>
      </div>
    )
  }
}

export default rdxConnect(Profile);