import { httpService, authService } from "libs/react-mpk";
import { utilsService } from "services";
import qs from "query-string";

export default {
  get: (params, addDataItem) => {
    let options = utilsService.generateGatewayOptions(`/notification`);
    options.params = utilsService.mapUrlParams(params);
    return httpService.streamGet(options, addDataItem);
  },
  unread: () => {
    let options = utilsService.generateGatewayOptions(`/notification/unread`);
    return httpService.get(options);
  },
  unreadAll: () => {
    let options = utilsService.generateGatewayOptions(`/notification/read_all`);
    return httpService.get(options);
  },
  download: (filename) => {
    let options = utilsService.generateGatewayOptions(
      `/transaction/export/${filename}/download`
    );
    let url = `${options.url}?${qs.stringify({
      access_token: authService.getAccessToken(),
    })}`;

    return window.open(url, "_blank");
  },
  put: (ids) => {
    let options = utilsService.generateGatewayOptions(`/notification`);
    options.data = { id: ids };
    return httpService.put(options);
  },
};
