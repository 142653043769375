import React from "react";
import { rdxConnect, httpService } from "libs/react-mpk";
import {
  Table,
  ContainerTransform,
  Empty,
  TableSetting,
  Hint,
} from "../libs/react-mpk";
import { Paper, Button } from "react-md";
import { merge } from "lodash";
import t from "counterpart";
import * as transactionService from "../modules/Main/User/Transaction/service";
import iziToast from "izitoast";

export default (getOptions) => (WrappedComponent) => {
  class tableWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.opt = merge(
        {
          bulkSelect: false,
          tableType: "",
          hint: {
            showIcon: true,
            children: "This is hint",
          },
          columns: [],
          itemActions: [],
          actions: [],
          fetchData: () => {},
          tableProps: {},
          tableSettingProps: {},
        },
        getOptions(props, {
          showForm: this.handleShowForm,
          deleteItem: this.handleDeleteItem,
        })
      );

      this.state = merge(
        {
          showForm: false,
          showFilter: true,
          selectedItem: null,
          loading: false,
        },
        this.opt.state
      );
    }

    handleShowForm = (showForm = true, selectedItem = null) =>
      this.setState({ showForm, selectedItem });
    handleDeleteItem = (item, onDelete = Promise, selector) => {
      selector = selector || { id: item.id };
      this.props.dialogActions.showConfirm(
        t.translate("sentence.dialog.delete.title"),
        t.translate("sentence.dialog.delete.message"),
        async (callback) => {
          try {
            await onDelete(item);
            if (selector)
              this.props.tableActions.deleteDataItemWithSelector(selector);
            callback(false, t.translate("sentence.info.deletedItem"));
          } catch (error) {
            callback(
              true,
              httpService.utils.parseErrorMessage(
                error,
                this.props.global.localeCode
              )
            );
          }
        },
        null,
        t.translate("word.yes"),
        t.translate("word.cancel")
      );
    };

    handleFetchData = async (params, resolve, reject, onDataItem) => {
      try {
        let { data, total } = await this.opt.fetchData(params, onDataItem);
        resolve(data, total);
      } catch (error) {
        reject(error);
      }
    };

    handleToggleFilter = async () => {
      let { showFilter } = this.state;
      console.log("huuh aya");
      this.setState({ showFilter: !showFilter });
    };

    render() {
      let actions = this.opt.actions.filter((d) =>
        String(d.show) !== "null" ? d.show : true
      );
      return (
        <WrappedComponent
          {...this.props}
          {...{
            state: this.state,
            handle: {
              toggleFilter: this.handleToggleFilter,
              showForm: this.handleShowForm,
              deleteItem: this.handleDeleteItem,
            },
          }}
        >
          <div className="mpk-full width height mpk-position relative mpk-animation slide-in mpk-layout">
            <Paper className="flex mpk-layout column">
              {this.opt.hint && <Hint {...this.opt.hint} />}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  padding: "0.5rem",
                  columnGap: "0.5rem",
                }}
              >
                {this.opt.tableType === "refund" && (
                  <Button
                    primary
                    style={{
                      border: "1px solid #f44336",
                      borderRadius: "6px",
                    }}
                    iconClassName="mdi mdi-download"
                    onClick={async () => {
                      try {
                        await transactionService.downloadAll();
                        iziToast.success({
                          message: "Permintaan Unduh Berhasil di Kirim",
                        });
                      } catch (error) {
                        iziToast.error({
                          message: "Terjadi Kesalahan Saat Download File!",
                        });
                      }
                    }}
                  >
                    Download Semua Detail Transfer
                  </Button>
                )}

                {this.props.table.selected.length > 0 && this.opt.bulkSelect ? (
                  <Button
                    primary
                    style={{
                      border: "1px solid #f44336",
                      borderRadius: "6px",
                    }}
                    iconClassName="mdi mdi-download"
                    onClick={async () => {
                      try {
                        await transactionService.downloadById({
                          refundId: this.props.table.selected.map(
                            (item) => item?.id
                          ),
                        });
                        iziToast.success({
                          message: "Permintaan Unduh Berhasil di Kirim",
                        });
                      } catch (error) {
                        iziToast.error({
                          message: "Terjadi Kesalahan Saat Download File!",
                        });
                      }
                    }}
                  >
                    Download Detail Transfer Terpilih{" "}
                    {this.props.table.selected.length > 0 &&
                      `(${this.props.table.selected.length})`}{" "}
                  </Button>
                ) : null}

                {this.props.table.selected.length > 0 && this.opt.bulkSelect ? (
                  <Button
                    primary
                    style={{
                      border: "1px solid #f44336",
                      borderRadius: "6px",
                    }}
                    iconClassName="mdi mdi-pencil"
                    onClick={() =>
                      this.props.dialogActions.showConfirm(
                        "Update Status Refund",
                        "Apakah Anda Yakin Ingin Update Status Refund?",
                        async (callback) => {
                          try {
                            await transactionService.putRefundStatus({
                              refundId: this.props.table.selected.map(
                                (item) => item?.id
                              ),
                            });
                            this.props.tableActions.deleteSelectedDataItems();
                            this.props.tableActions.reload();
                            callback(false, "Berhasil Ubah Status Refund!");
                          } catch (error) {
                            callback(
                              true,
                              httpService.utils.parseErrorMessage(
                                error,
                                this.props.global.localeCode
                              )
                            );
                          }
                        },
                        null,
                        t.translate("word.yes"),
                        t.translate("word.cancel")
                      )
                    }
                  >
                    Edit Status Terpilih{" "}
                    {this.props.table.selected.length > 0 &&
                      `(${this.props.table.selected.length})`}
                  </Button>
                ) : null}
              </div>
              <div className="flex mpk-layout">
                <Table
                  baseId={`balance-account-list`}
                  itemActions={this.opt.itemActions}
                  columns={this.opt.columns}
                  fetchData={this.handleFetchData}
                  translate={false}
                  isPaging={true}
                  {...this.opt.tableProps}
                />
                {actions.length > 0 && (
                  <div className="flex-none mpk-border thin solid dark left mpk-padding-S padding-all">
                    {actions.map((d) => {
                      switch (d.type) {
                        case "button":
                        default:
                          return (
                            <Button
                              icon
                              iconClassName={d.iconClassName}
                              onClick={d.onClick}
                            />
                          );
                      }
                    })}
                  </div>
                )}
              </div>
            </Paper>
            {this.state.showFilter && (
              <ContainerTransform className="flex-none mpk-layout">
                <Empty width={16} className="flex-none mpk-hide-xs" />
                <Paper className="flex-none">
                  <TableSetting
                    onSearch={this.handleFetchData}
                    translate={false}
                    showPeriods={false}
                    {...this.opt.tableSettingProps}
                  />
                </Paper>
              </ContainerTransform>
            )}
          </div>
        </WrappedComponent>
      );
    }
  }

  return rdxConnect(tableWrapper);
};
