/**
 * Created by dwiargo on 11/30/17.
 */

import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from 'react-translate-component';
import {
  Tabs, Tab, Button
} from 'react-md';
import counterpart from 'counterpart';

import TableSearch from './TableSearch';
import TableColumn from './TableColumn';
import * as tableActions from '../../redux/actions/tableActions';
import * as toastActions from '../../redux/actions/toastActions';
import { httpService } from '../..';
import _ from 'lodash';

const tabs = ["Search", "Column"];
const getQueryName = baseId => (`${baseId}-query`);
const defaultError = () => ({
  isError:false,
  title:'',
  message:''
});

class TableSetting extends Component{
  constructor(){
    super();
    this.state = {
      currentTab:tabs[0]
    }
  }

  onTabChange = (index, tabId) => {
    this.setState({currentTab:tabs[index]});
  };

  onSearch = (newParams, isReset=false) => {
    let { params } = this.props.table;
    params = _.merge(params, newParams);
    this.props.tableActions.setProperties({
      isLoading: true,
      error:defaultError(),
      params:params
    });
    this.handleSaveQuery(params)
    //this.props.onSearch(this.props.table.params).then(response => {
    //  this.props.tableActions.setProperties({
    //    isLoading: false,
    //    data: response.data
    //  })
    //})

    let _data = [];
    let tReceive = 0;
    let { page, size } = params;

    this.props.onSearch(this.props.table.params, (data=[], total) => {
      total = Number(total);

      tReceive = httpService.utils.totalItemWillReceive(page, size, total);
      this.props.tableActions.setProperties({
        isLoading: false,
        data: data,
        params:{
          total:total,
        }
      })
    }, err => {
      // let response = typeof err === 'object' ? err.response : err;
      // let message = response === 'object' ? (
      //   response.errorMessage[this.props.global.localeCode] ? response.errorMessage[this.props.global.localeCode] : response
      // ) : response
      let { message } = err;
      
      message = typeof message === 'object' ? message[this.props.global.localeCode] : message;
      this.props.toastActions.izi(
        this.props.translate ? counterpart.translate('word.failed') : 'Failed',
        message,
        'warning'
      )
      this.props.tableActions.setProperties({
        isLoading: false,
      });
    }, item => {
      _data.push(item);
      if(_data.length === tReceive){
        this.props.tableActions.setProperties({
          data:_data,
          isLoading:false
        })
      }
      // this.props.tableActions.addDataItem(item, true);
    }, isReset)
  }

  handleSaveQuery = (params) => {
    let { saveQuery, baseId } = this.props;
    if(saveQuery) localStorage.setItem(getQueryName(baseId), JSON.stringify(params));
  }

  render(){
    let { fullHeight, searchOnly, showPeriods, extraFilter } = this.props;
    const switchTab = () => {
      switch (this.state.currentTab) {
        case 'Search':
          return (
            <TableSearch
              translate={this.props.translate}
              table={this.props.table}
              tableActions={this.props.tableActions}
              onSearch={this.onSearch}
              showPeriods={showPeriods}
              extraFilter={extraFilter}
              baseId={this.props.baseId}
              saveQuery={this.props.saveQuery}
            />
          );
        case 'Column':
          return (
            <TableColumn
              translate={this.props.translate}
              columns={this.props.table.columns}
              tableActions={this.props.tableActions}
            />
          );
        default:
          return (null);
      }
    }

    return(
      <div 
        className={`mpk-table-setting mpk-layout column ${fullHeight ? 'mpk-full height' : ''}`}
        style={{
          height:fullHeight ? '100%' : 400
        }}
      >
        {searchOnly ? null : (
          <Tabs tabId="simple-tab" mobile={true} onTabChange={this.onTabChange}>
            {tabs.map((d, i) => (
              <Tab key={i} label={(
                <Translate content={"word."+String(d).toLowerCase()}/>
              )}/>
            ))}
          </Tabs>
        )}
        <div className="flex" style={{position: 'relative'}}>
          { searchOnly ? <TableSearch
              translate={this.props.translate}
              table={this.props.table}
              tableActions={this.props.tableActions}
              onSearch={this.onSearch}
              baseId={this.props.baseId}
              saveQuery={this.props.saveQuery}
            /> : switchTab()}
        </div>
        <div className="mpk-show-xs mpk-padding-S padding-left padding-right padding-bottom">
          <Button
            style={{width:"100%"}}
            raised secondary onClick={this.props.onHide}
            >
            {counterpart.translate('word.close')}
          </Button>
        </div>
      </div>
    )
  }
}

TableSetting.defaultProps = {
  baseId: 'mpk-table',
  saveQuery: false,
  fullHeight:true,
  searchOnly:false,
  onHide:() => {}
}

TableSetting.propTypes = {
  onSearch:PropTypes.func.isRequired,
  connect:PropTypes.shape({
    properties:PropTypes.object.isRequired,
    actions:PropTypes.object.isRequired
  }),
  translate:PropTypes.bool,
  fullHeight:PropTypes.bool
};

const mapStateToProps = (state, props) =>{
  return{
    table: props.connect ? props.connect.properties : state.table,
    global: state.global
  }
};

const mapDispatchToProps = (dispatch,props) => ({
  tableActions: bindActionCreators((props.connect ? props.connect.actions : tableActions), dispatch),
  toastActions: bindActionCreators(toastActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TableSetting);