import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Bank from './Bank';
import * as TransactionType from './TransactionType';
import * as UserGroup from './UserGroup';
import * as RefundApproval from './RefundApproval';
import * as Application from './Application';

export default () => (
  <Switch>
    <Route path="/main/setting/bank-list" render={(props) => (
      <Bank.List router={props}/>
    )}/>
    <Route path="/main/setting/transaction-type-list" render={(props) => (
      <TransactionType.List router={props}/>
    )}/>
    <Route path="/main/setting/user-group-list" render={(props) => (
      <UserGroup.List router={props}/>
    )}/>
    <Route path="/main/setting/user-group/:id/:tab" render={(props) => (
      <UserGroup.Details router={props}/>
    )}/>
    <Route path="/main/setting/refund-approval-list" render={(props) => (
      <RefundApproval.List router={props}/>
    )}/>
    <Route path="/main/setting/application-list" render={(props) => (
      <Application.List router={props}/>
    )}/>
  </Switch>
)