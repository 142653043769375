import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as BalanceAccount from './BalanceAccount';
import * as Transaction from './Transaction';

export default () => (
  <Switch>
    <Route path="/main/user/balance-account-list" render={(props) => (
      <BalanceAccount.List router={props}/>
    )}/>
    <Route path="/main/user/balance-account/:id/:tab" render={(props) => (
      <BalanceAccount.Details router={props}/>
    )}/>
     <Route path="/main/user/transaction/:tab" render={(props) => (
      <Transaction.Wrapper router={props}/>
    )}/>
  </Switch>
)