import React from 'react';
import moment from 'moment';

const NotificationItem = ({ message, date, actions }) => (
  <div className="mpk-notification-item">
    <div className="mpk-font-size-S mpk-font-color-D3 mpk-margin-S margin-bottom">{moment(date).format('lll')}</div>
    <div className="message">{message}</div>
    { actions ? (
      <div className="actions mpk-layout mpk-padding-S padding-top padding-bottom">
        {actions}
      </div>
    ) : null }
  </div>
)

export default NotificationItem;