import React from 'react';
import Form from '../Form';
import { rdxConnect } from 'libs/react-mpk';

class UserGroupProfile extends React.Component{
  render(){
    let { userGroup } = this.props.tmpObject;
    return(
      <div className="mpk-padding-N padding-top padding-bottom">
        <Form
          asDialog={false}
          item={userGroup}
        />
      </div>
    )
  }
}

export default rdxConnect(UserGroupProfile);