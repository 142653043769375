import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { httpService, rdxConnect, Hint } from 'libs/react-mpk';
import * as service from '../../service';
import { find } from 'lodash';
import * as PIN from '../../PIN';

class RefundForm extends React.Component {
  state = {
    banks: [],
  }

  componentDidMount() {
    this.fetchBankData();
  }

  fetchBankData = async () => {
    try {
      let banks = [];
      await service.getBank({ page: 1, size: 500 }, item => {
        banks.push(item);
        this.setState({ banks });
      })
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const hasPermission = this.props.auth.hasPermission('POST:/user_refund');
    const { banks } = this.state;
    let { item } = this.props;
    return (
      <Wrapper.Form
        title={t.translate('routes.widget.refund.formTitle')}
        columnValidations={['name', 'code']}
        dialogStyle={{
          width: '100%',
          maxWidth: 560
        }}
        showSubmit={item ? false : true}
        defaultFormData={() => {
          let bank = banks.length > 0 && banks[0]
          return {
            _userBankAccountId: bank ? bank.id : null,
            amount: item ? item.amount - item.transferFee : 0,
            userBankAccount: bank,
            transferFee: 0,
            totalAmount: 0,
          }
        }}
        definitions={[
          {
            type: 'other',
            component: (banks.length === 0 && (
              <Hint>{t.translate('routes.widget.refund.noBank')}</Hint>
            ))
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.amount'),
            key: 'amount',
            required: true,
            readOnly: !hasPermission,
            disabled: item ? true : false,
            helpText: t.translate('routes.widget.refund.amountHelpText')
          },
          {
            inputType: Wrapper.Form.types.SELECT,
            label: t.translate('column.bank'),
            key: '_userBankAccountId',
            value: item?.userBankAccount?.id,
            menuItems: this.state.banks.map(d => ({ label: (`${d.bank.name} - ${d.accountNo} an ${d.accountName}`), value: d.id })),
            readOnly: !hasPermission,
            disabled: item ? true : false,
            className: 'mpk-full width',
            helpText: t.translate('routes.widget.refund.selectBankHelpText')
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('routes.widget.refund.feeAdminBank'),
            key: 'transferFee',
            required: true,
            disabled: item ? true : false,
            readOnly: true,
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('routes.widget.refund.totalNominal'),
            key: 'totalAmount',
            required: true,
            disabled: item ? true : false,
            readOnly: true,
          },
        ]}
        onBeforeChange={(key, value, formData) => {
          if (key === 'amount') {
            formData.amount = value.replace(/\D/g, '');
            formData.totalAmount = parseInt(formData.amount) > 0 ? parseInt(formData.amount) + parseInt(formData.transferFee) : parseInt(formData.transferFee)
          }
          if (key === '_userBankAccountId') {
            formData.userBankAccount = find(this.state.banks, { id: value });
            formData.transferFee = formData.userBankAccount.bank.transactionFee
            formData.totalAmount = parseInt(formData.amount) > 0 ? parseInt(formData.amount) + parseInt(formData.transferFee) : parseInt(formData.transferFee)
          }
          return value;
        }}
        onSubmit={async (formData, isEdit, callback) => {
          try {
            await PIN.required();
            if (parseInt(formData.amount) < 500000) {
              callback(true, t.translate('routes.widget.refund.amountHelpText'))
            } else {
              let res = await service.postRefund(formData);
              if (String(res.data.responseCode).startsWith(1)) {
                this.props.onSuccess(res.data.data);
                callback(false, t.translate('sentence.info.savedItem'));
              } else {
                callback(true, res.data.errorDescription)
              }
            }
          } catch (error) {
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
          }
        }}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(RefundForm);