import * as types from '../actions/actionTypes';

const initialState = () => ({})

export default (state=initialState(), action={}) => {
    switch(action.type){
        case types.TMP_OBJECT_SET_PROPERTIES:
            return Object.assign({}, state, action.properties);

        case types.TMP_OBJECT_RESET:
            return Object.assign({}, state, initialState);

        default: return state;
    }
}