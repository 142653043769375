import React from "react";
import { Router } from "react-router-dom";
import { rdxConnect, Appbar, Sidebar } from "libs/react-mpk";
import t from "counterpart";
import routes from "./routes";
import GlobalNotification from "../../components/GlobalNotification/GlobalNotification";

class Main extends React.Component {
  render() {
    let { global, auth } = this.props;
    return (
      <div className="mpk-layout column fill">
        <Appbar
          subname="User Balance Management"
          showVersion
          profileMenu={[
            {
              label: t.translate("word.profile"),
              materialIcon: "perm_identity",
              onClick: () => window.open(global.appConsole.userAccount.url),
            },
          ]}
          consoleShortcut={Object.keys(global.appConsole).map(
            (key) => global.appConsole[key]
          )}
          translate
          // notification={<GlobalNotification />}
        />
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            isLoading={!auth.isLoggedIn}
            menus={auth.user.menus}
            getValueByLocaleCode={true}
            collapsible={false}
            headerElements={
              <div className="mpk-padding-N top left right">
                <div className="mpk-font-size-L mpk-margin-S margin-bottom">
                  <div className="mpk-font-size-N">
                    {t.translate("welcome.deck")}
                  </div>
                  <div className="mpk-font-weight-B mpk-font-color-primary">
                    {t.translate("welcome.head")}
                  </div>
                </div>
                <div className="mpk-font-size-NS mpk-font-color-D3 mpk-padding-N padding-bottom">
                  {t.translate("welcome.info")}
                </div>
              </div>
            }
          />
          <Router history={this.props.router.history}>
            {routes(this.props.global)}
          </Router>
        </div>
      </div>
    );
  }
}

export default rdxConnect(Main);
