import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { httpService, rdxConnect } from 'libs/react-mpk';
import * as service from '../../service';
import * as bankService from '../../../Main/Setting/Bank/service';
import { find } from 'lodash';
import * as PIN from '../../PIN';

class BankAccountForm extends React.Component{
  state = {
    banks: []
  }

  componentDidMount(){
    this.fetchBankData();
  }

  fetchBankData = async () => {
    try {
      let banks = []; 
      await bankService.get({page: 1, size: 500}, item => {
        banks.push(item);
        this.setState({banks});
      })
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    const hasPermission = this.props.auth.hasPermission('POST:/user_bank');

    return(
      <Wrapper.Form
        title={t.translate('routes.setting.bank.formTitle')}
        columnValidations={['name', 'code']}
        defaultFormData={() => {
          let bank = this.state.banks.length > 0 && this.state.banks[0]
          return {
            accountName: '',
            accountNo: '',
            bank: bank || null,
            bankId: bank ? bank.id : null
          }
        }}
        definitions={[
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.accountName'),
            key: 'accountName',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.accountNo'),
            key: 'accountNo',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.SELECT,
            label: t.translate('column.bank'),
            key: 'bankId',
            menuItems: this.state.banks.map(d => ({label: d.name, value: d.id})),
            readOnly: !hasPermission
          }
        ]}
        onBeforeChange={(key, value, formData) => {
          if(key === 'accountNo') value = value.replace(/\D/g, '');
          if(key === 'bankId') {
            formData.bank = find(this.state.banks, {id: value});
          }
          return value;
        }}
        onSubmit={async (formData, isEdit, callback) => {
          try {
            await PIN.required();
            let res = isEdit ? await service.putBank(formData.id, formData) : await service.postBank(formData);
            this.props.onSuccess(res.data);
            callback(false, t.translate('sentence.info.savedItem'));
          } catch (error) {
            console.log(error)
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
          }
        }}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(BankAccountForm);