/**
 * Created by dwiargo on 1/8/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DividerLabel.scss';

class DividerLabel extends Component{
  render(){
    let { className="", label, sticky, style={background:'white'} } = this.props;
    return(
      <div 
        className={`mpk-divider-label ${className} ${sticky ? 'mpk-position sticky' : ''}`}
        style={style}
      >
        {label}
      </div>
    )
  }
}

DividerLabel.propTypes = {
  label:PropTypes.string,
  className: PropTypes.string,
  sticky: false
};

export default DividerLabel;