import React from 'react';
import './Masonry.scss';

class Masonry extends React.Component{
  componentDidMount(){
    setTimeout(() => {
      this.resizeAllGridItems();
    }, 200);
  }

  resizeAllGridItems = () => {
    let { baseId } = this.props;
    let allItems = document.getElementsByClassName(`${baseId}-grid`);
    for(let x=0;x<allItems.length;x++){
      this.resizeGridItem(allItems[x]);
    }
  }

  resizeGridItem = (item) => {
    let { baseId } = this.props;
    let grid = document.getElementsByClassName(`${baseId}`)[0];
    let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    let content = item.querySelector('.msn-content');
    let rowSpan = Math.ceil((content.getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
    content.style.height = '100%';
  }


  render(){
    let{items, className="", baseId} = this.props
    return (
      <div className={`mpk-masonry ${baseId} ${className}`}>
        {items.map((item, i) => (
          <div 
            key={`${baseId}-grid-${i}`}
            className={`${baseId}-grid`}
          >
            <div className="msn-content">
              {item}
            </div>
          </div>
        ))}
      </div>
    )
  }
} 

Masonry.defaultProps = {
  baseId: 'mpk-masonry-base-id',
  items: []
}

export default Masonry;