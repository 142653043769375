/**
 * Created by dwiargo on 2/14/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Treebeard } from 'react-treebeard'
import './Treeview.scss';

class Treeview extends Component{
  constructor(){
    super();
    this.state = {
      cursor:null
    };
  }

  onToggle = (node, toggled) => {
    if(this.state.cursor){
      let cursor = this.state.cursor;
      cursor.active = false;
      this.setState({
        cursor:cursor
      })
    }
    node.active = true;
    if(node.children){ node.toggled = toggled; }
    this.setState({ cursor: node });
  }

  render(){
    return(
      <div className="mpk-treeview">
        <Treebeard
          className="mpk-full width"
          data={this.props.data}
          onToggle={this.onToggle}
          />
      </div>
    )
  }
}

Treeview.propTypes = {
  data:PropTypes.shape({
    name:PropTypes.string,
    toggled:PropTypes.bool,
    children:PropTypes.arrayOf(
      PropTypes.shape({
        name:PropTypes.string,
        toggled:PropTypes.bool,
        children:PropTypes.array
      })
    )
  })
}

export default Treeview;