import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Profile from './Profile';
import Details from './Details';

export default () => (
  <Switch>
    <Route path="/widget/profile" render={(props) => (
      <Profile router={props}/>
    )}/>
     <Route path="/widget/details/:tab" render={(props) => (
      <Details router={props}/>
    )}/>
  </Switch>
)