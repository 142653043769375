import React from 'react';
import { Route, Switch } from 'react-router-dom';

import User from './User';
import Setting from './Setting';

export default () => (
  <Switch>
    <Route path="/main/user" render={(props) => (
      <User router={props}/>
    )}/>
    <Route path="/main/setting" render={(props) => (
      <Setting router={props}/>
    )}/>
  </Switch>
)