/**
 * Created by dwiargo on 12/19/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import counterpart from 'counterpart';

import './SectionHeader.scss';

class SectionHeader extends Component {
  render() {
    let { body, title, translate, footer, background } = this.props;
    return (
      <div className="mpk-section-header">
        { background ? (
          <div className="bg" style={{
            backgroundImage:"url("+background+")"
          }}/>
        ) : (null)}
        <div className="center-container">
          <div className="title">{translate ? counterpart.translate(title) : title}</div>
          {body ? (
              <div className="body body-text">{renderHTML(body)}</div>
            ) : (null)
          }
          {footer ? (
              <div className="footer mpk-margin-N top">{footer}</div>
            ) : (null)
          }
        </div>
      </div>
    )
  }
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  footer:PropTypes.any,
  translate: PropTypes.bool
};

export default SectionHeader;