import React, { Component } from 'react';
import ills from '../../assets/illustrasi-line-dash-lamp.svg';
import Translate from 'react-translate-component';
import t from 'counterpart';
import './NotFoundData.scss';

class NotFoundData extends Component{ai
    render(){
        let { title, message, todo, errorDescription, translate=true, className="" } = this.props;
        return(
            <div className={`mpk-not-found mpk-layout justify-center align-center column-sm mpk-full width height ${className}`}>
                <img src={ills} className="ills" role="presentation" alt="not-found-icon"/>
                <div className="info">
                    <div className="mpk-font-size-XXL mpk-font-weight-B">{title || 'Oops'}</div>
                    {translate ? (
                        <Translate content={message ? message : "sentence.table.dataNotFound"}/>
                    ) : (
                        message ? message : t.translate('sentence.table.dataNotFound')
                    )}
                    <div className="todo mpk-font-color-D3 mpk-font-size-NS">
                        {translate ? (
                            <Translate content={ todo ? todo : (message ? message : 'sentence.table.notFoundTodo.changeQuery')}/>
                        ) : (
                            todo || errorDescription || <Translate content="sentence.table.notFoundTodo.changeQuery"/>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFoundData;