import { httpService } from "libs/react-mpk";
import { utilsService } from "services";

const path = "/transaction";
export const get = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
};

export const getById = (id) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`);
  return httpService.get(options);
};

export const getRefund = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/refund`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
};

export const downloadAll = () => {
  let options = utilsService.generateGatewayOptions(`${path}/export/all`);
  return httpService.get(options);
};

export const downloadById = (data) => {
  let options = utilsService.generateGatewayOptions(`${path}/export/by_id`);
  options.data = data;
  return httpService.post(options);
};

export const getRefunded = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/refunded`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
};

export const putRefundStatus = (data) => {
  let options = utilsService.generateGatewayOptions(`${path}/refunded`);
  options.data = data;
  return httpService.put(options);
};

export const getTopUp = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/top_up`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
};

export const getPayment = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/payment`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
};
