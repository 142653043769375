import uid from 'uid';
import authService from './authService';
import moment from 'moment';

const service = {
  orderNo:() => {
    const datetime = moment().format('YYMMDDhhmm');
    const randNum = String(10000 + Math.floor(Math.random()*99999)).substr(0, 5);
    return `${String(datetime)}${randNum}`
  },
  uniqueId:() => {
    let token = authService.getAccessToken();
    let _uid  = uid(6);
    let ts = String(new Date().valueOf());
    return String(`${String(token).substr(0,6)}${_uid}${String(ts).substr(ts.length - 5, 4)}`).toUpperCase()
  }
}

export default service;