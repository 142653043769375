import {createStore, compose} from 'redux';
import {combineReducers} from 'redux';
import {
  auth, table, global, toast, dialog, tmpObject
} from '../libs/react-mpk/redux/reducers';

// enable redux devtools... can this be done with Webpack instead?
const enhancers = compose(
  window.devToolsExtension ? window.devToolsExtension() : f => f
)

const rootReducer = combineReducers({
  auth, table, global, toast, tmpObject, dialog
});

export default (initialState) => {
  return createStore(rootReducer, initialState, enhancers);
};
