import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageThumbnail from './ImageThumbnail';

class ImageGallery extends Component{
  constructor(){
    super();
    this.state = {
      hasMore:false
    }
  }

  selectItem = item => {
    this.setState({
      selectedItem:item
    })
  }

  render(){
    let {items, baseUrl, onSelect} = this.props;
    let {selectedItem} = this.state;
    return(
      <div className="mpk-image-gallery mpk-layout wrap justify-center">
        {items.map((d, i) => (
          <ImageThumbnail 
            isSelected={selectedItem && d.filename === selectedItem.filename ? true : false}
            image={d} key={i} baseUrl={baseUrl}
            style={{
              margin: 4,
              width: 92,
              height: 92
            }}
            onClick={(item) => {
              this.selectItem(item);
              onSelect(item);
            }}
            />
        ))}
      </div>
    )
  }
}

ImageGallery.defaultProps = {
  items:[]
}

ImageGallery.propTypes = {
  onRequestMoreData:PropTypes.func,
  onSelect:PropTypes.func,
  baseUrl:PropTypes.string,
  items:PropTypes.arrayOf(PropTypes.shape({
    src:PropTypes.string.isRequired,
    caption:PropTypes.string,
    type:PropTypes.string
  }))
}

export default ImageGallery;