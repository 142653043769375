import React from 'react'
import { FontIcon } from 'react-md'
import moment from 'moment'

class CountDown extends React.Component {
    state = {
        diff: 0,
        day: 0,
        hour: 0,
        minute: 0,
        second: 0
    }

    componentDidMount() {
        let dateCountdown = this.props.endDate
        if (dateCountdown) {
            let diff = (moment(dateCountdown).diff(this.props.timestamp, 'second'))
            if (diff > 0) {
                this.setState({ diff }, () => {
                    this.onEnterFrame()
                    this.tick = setInterval(() => {
                        this.onEnterFrame()
                    }, 1000)
                })
            }
        }
    }

    onEnterFrame = () => {
        let { diff } = this.state
        if (diff > 0) {
            diff--

            let _diff = diff
            // let day = Math.floor(_diff / (Math.pow(60, 2) * 24))
            // _diff -= day * (Math.pow(60, 2) * 24)
            let hour = Math.floor(_diff / Math.pow(60, 2))
            hour = hour >= 24 ? 23 : hour
            _diff -= hour * Math.pow(60, 2)
            let minute = Math.floor(_diff / 60)
            minute = minute >= 60 ? 59 : minute
            _diff -= minute * 60
            let second = _diff
            this.setState({
                hour, minute, second, diff
            })
        } else if (this.tick) {
            clearInterval(this.tick)
        }
    }

    render() {
        let { className = '', iconClassName, ...otherProps } = this.props
        let { day, hour, minute, second, diff } = this.state
        return (
            <div
                className={`mpk-countdown ${className}`}
                {...otherProps}
            >
                <div className="mpk-layout align-center justify-between mpk-padding-S padding-top padding-bottom mpk-font-size-M">
                    { iconClassName && <FontIcon iconClassName="mdi mdi-timer" className="mpk-margin-N margin-right" />}
                    {/* <div className="time flex mpk-margin-S margin-right"><b>{day}</b> <div className="label">Hari</div></div> */}
                    {/* <span>:</span> */}
                    <div className="flex mpk-margin-S margin-right mpk-align-center"><b>{hour}</b></div>
                    <span>:</span>
                    <div className="flex mpk-margin-S margin-right mpk-align-center"><b>{minute}</b></div>
                    <span>:</span>
                    <div className="flex mpk-margin-S margin-right mpk-align-center"><b>{second}</b></div>
                </div>
            </div>
        )
    }
}

CountDown.defaultProps = {
    iconClassName: 'mdi mdi-timer'
}

export default CountDown