/**
 * Created by dwiargo on 12/13/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import counterpart from 'counterpart';
import renderHTML from 'react-render-html';
import './Section.scss';

class Section extends Component{
  render(){
    let {title, deck, body, theme, translate, className, footer} = this.props;
    return(
      <div className={"mpk-section " +className+" "+(theme === "dark" ? "dark" : "")}>
        <div className="title">{translate ? counterpart.translate(title) : title}</div>
        {deck ? (<div className="deck decorative-text">{renderHTML(deck)}</div>) : (null)}
        {body ? (<div className="body body-text mpk-body">{renderHTML(body)}</div>) : (null)}
        <div className="footer">{footer}</div>
      </div>
    )
  }
}

Section.propTypes = {
  title:PropTypes.string.isRequired,
  body:PropTypes.node,
  footer:PropTypes.node,
  theme:PropTypes.string,
  translate:PropTypes.bool
}

export default Section;