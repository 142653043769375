/**
 * Created by dwiargo on 12/4/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import SidebarInfo from './SidebarInfo';
import SidebarBalance from './SidebarBalance';
import { CircularProgress } from 'react-md';
import './Sidebar.scss';

import * as tableActions from '../../redux/actions/tableActions';
import * as globalActions from '../../redux/actions/globalActions';

class Sidebar extends Component{
  componentWillMount(){
    this.props.globalActions.setProperties({currentPath:window.location.pathname})
  }

  render(){
    let {menus, getValueByLocaleCode, global, info, isLoading, showFooter= true} = this.props;
    return(
      <div className={"mpk-sidebar mpk-layout column flex-none " + (global.appbarLeftActionToggled ? "" : "hide")}>
        {this.props.headerElements}
        <SidebarBalance/>
        {isLoading ? (
          <div style={{width:64}}><CircularProgress id="mpk-sidebar-progress" scale={.8}/></div>
        ) : (null)}
        {menus ? (
          <SidebarMenu
            menuClass={this.props.menuClass}
            collapsible={this.props.collapsible}
            menus={menus}
            getValueByLocaleCode={getValueByLocaleCode}
            global={global}
            tableActions={this.props.tableActions}
          />
        ) : (<div className="flex"/>)}
        {this.props.footerElements}
        {info ? (
          <SidebarInfo {...info}/>
        ) : (null)}
        {showFooter ?
          <SidebarFooter
            global={global}
          />
        :null}
        </div>
    )
  }
}

Sidebar.propTypes = {
  menus:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.any.isRequired,
      children:PropTypes.arrayOf(
        PropTypes.shape({
          label:PropTypes.any.isRequired,
          iconClassName:PropTypes.string.isRequired,
          path:PropTypes.string,
          onClick:PropTypes.func
        })
      )
    })
  ),
  getValueByLocaleCode:PropTypes.bool
}

const mapStateToProps = state => ({
  global:state.global
});

const mapDispatchToProps = dispatch => ({
  tableActions:bindActionCreators(tableActions, dispatch),
  globalActions:bindActionCreators(globalActions, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);
