/**
 * Created by dwiargo on 10/11/17.
 */

const localStorageService = {
  set:(key="", value) => {
    localStorage.setItem(key, value);
  },
  get:(key="") => {
    return localStorage.getItem(key);
  },
  remove:(key="") => {
    localStorage.removeItem(key)
  }
}

export default localStorageService;