import * as types from './actionTypes';

export const setProperties = (properties) => {
  return {
    type:types.TMP_OBJECT_SET_PROPERTIES,
    properties
  }
};

export const reset = () => {
  return {
    type:types.TMP_OBJECT_RESET
  }
}