import React, { useState, useEffect, useRef } from 'react';
import {
    Paper,
    Button,
    DialogContainer,
    TextField,
    Card,
    CardContent,
} from 'react-md';
import authService from '../services/authService';
import hostMpk from '../services/hosts';
import './TFAPage.scss'
import Logo from '../components/Logo/Logo';
import { httpService } from '../../react-mpk';


const TFAPage = ({ visible, onFinish, isTfa, props }) => {
    const textInputConfirm = useRef(null);
    const textInputVal = useRef(null);
    const [qr, setQr] = useState('')
    const [key, setKey] = useState('')
    const [isRecover, setIsRecover] = useState(false)
    const getStatusTFA = authService.getIsTFA()
    console.log("🚀 ~ file: TFAPage.js:24 ~ TFAPage ~ getStatusTFA:", getStatusTFA)
    const env = hostMpk.getAll()
    const TfaMandatory = env.TfaMandatory ? env.TfaMandatory:true;

    useEffect(() => {
        if (visible) {
            if (getStatusTFA === 'true') {
                textInputVal.current.focus();
            }
            // httpService.get({
            //     url: `https://api.bdg.pajakku.com/auth/tfa/recovery_code`
            // })
        }
    }, [visible])
    const handleReqQr = async () => {
        try {
            let q = {}
            q['key'] = key
            let dataQR = await httpService.get({
                url: `${env.iam.host && env.iam.host}/auth/tfa/set`,
                params: q
            })
            setQr(dataQR.data)
            setKey('')
            textInputConfirm.current.focus();

        } catch (error) {
            // console.log("🚀 ~ file: TFAPage.js ~ line 47 ~ handleReqQr ~ error", error.message.id)
            props.toastActions.izi(
                'Autentikasi',
                error.message ? error.message.id : 'Gagal Mengambil QR',
                'warning'
            )
        }
    }
    const handleConfirmQr = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            if (isRecover && !qr) {
                handleReqQr()
            } else {
                let dataQR = await httpService.get({
                    url: `${env.iam.host}/auth/tfa/${key}/${getStatusTFA === 'true' && !qr ? 'validate' : 'confirm'}`
                })
                props.toastActions.izi(
                    'Autentikasi',
                    'Autentikasi berhasil',
                    'success'
                )
                authService.setIsTFA('DONE')
                onFinish()
            }
        } catch (error) {
            props.toastActions.izi(
                'Autentikasi',
                error.message ? error.message : 'Autentikasi Gagal',
                'warning'
            )
        }
    }
    const handleSkipTFA = async () => {
        if (TfaMandatory !== true) {
            authService.setSkipTFA('SKIP_TFA')
            onFinish();
        }
    }
    const logout = () => {
        authService.logout(null);
    }
    return (
        <DialogContainer
            id="simple-full-page-dialog"
            visible={visible}
            // pageX={pageX}
            // pageY={pageY}
            fullPage
            // onHide={}
            aria-labelledby="simple-full-page-dialog-title"
        >
            <div className="mpk-error-page mpk-layout fill column align-center justify-center">
                {/* <Paper className='mpk-layout fill column align-center justify-center'> */}
                <Logo
                    style={{
                        height: 48
                    }}
                />
                {getStatusTFA === 'true' && !qr ?
                    <Card className="mpk-layout column align-center mpk-margin-N margin-all mpk-padding-N padding-all" style={{
                        maxWidth: 500
                    }}>
                        <div className="mpk-layout mpk-margin-N bottom top">
                            <div className="status-text text-body">
                                {`${isRecover ? 'Recover' : 'Kode Autentikasi'} (Two Factor Authentication)`}
                            </div>
                        </div>
                        <p className="error-message mpk-body text-body mpk-dont-break-out">
                            {
                                isRecover ? `Silahkan masukan KEY Recovery yang anda simpan.` : `Silahkan masukan kode autentikasi yang didapat di Google Authenticator.`
                            }
                        </p>
                        <form className="mpk-layout column align-end" id="auth-form" onSubmit={handleConfirmQr} autocomplete={false}>
                            <TextField
                                id="application-form-kode"
                                label={'Input Kode'}
                                ref={textInputVal}
                                value={key}
                                type='number'
                                onChange={text => setKey(text)}
                                required
                            />
                            <div className={`mpk-layout row ${isRecover ? 'justify-end' : 'justify-between'} mpk-full width mpk-margin-N margin-top`}>
                                {!isRecover &&
                                    <Button
                                        secondary raised
                                        className="mpk-margin-S right"
                                        onClick={() => {
                                            setIsRecover(true)
                                            setKey('')
                                        }}
                                        style={{
                                            // width: 200
                                        }}
                                    >
                                        Recover
                                    </Button>
                                }
                                <div className='mpk-layout row'>
                                    <Button
                                        secondary raised
                                        className="mpk-margin-S right"
                                        onClick={logout}
                                        style={{
                                            // width: 200
                                        }}
                                    >
                                        Back To Login
                                    </Button>
                                    <Button
                                        primary raised
                                        // onClick={handleConfirmQr}
                                        type='submit'
                                        style={{
                                            // width: 200,
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                    :
                    <div className="mpk-layout column-sm align-center">
                        {qr ?
                            <Card className="mpk-margin-N margin-all mpk-padding-N padding-all" style={{
                                maxWidth: 600
                            }}>
                                <div className='flex mpk-layout column align-center'>
                                    <div className="mpk-layout mpk-margin-N bottom top">
                                        <div className="status-text text-body">{'Kode Autentifikasi (Two Factor Authentication)'}</div>
                                    </div>
                                    <p className="error-message mpk-body text-body mpk-dont-break-out">
                                        {'Silahkan Scan QR CODE ini di Google Authenticator dan masukan kode autentikasi yang didapat.'}
                                    </p>
                                    {/* <Media aspectRatio="1-1"> */}
                                    <img src={qr} style={{
                                        width: 300,
                                        height: 300
                                    }} />
                                    {/* </Media> */}
                                    <div className="flex mpk-layout row align-center">
                                        <form className="mpk-layout column align-end" id="auth-form" onSubmit={handleConfirmQr} autocomplete={false}>
                                            <TextField
                                                id="application-form-kode"
                                                label={'Input Kode'}
                                                ref={textInputConfirm}
                                                value={key}
                                                type='number'
                                                onChange={text => setKey(text)}
                                                required
                                            />
                                            <div className='mpk-layout row mpk-margin-N margin-top'>
                                                <Button
                                                    secondary raised
                                                    className="mpk-margin-S right"
                                                    onClick={logout}
                                                    style={{
                                                    }}
                                                >
                                                    Back To Login
                                                </Button>
                                                <Button
                                                    primary raised
                                                    // onClick={handleConfirmQr}
                                                    type='submit'
                                                    style={{
                                                    }}
                                                >
                                                    Submit
                                                </Button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Card>
                            :
                            <div className="flex body-content mpk-layout column">
                                <div className="mpk-layout mpk-margin-N bottom top">
                                    <div className="status-text text-body">{'Apakah anda akan mengaktifkan Two Factor Authentication?'}</div>
                                </div>
                                <p className="error-message mpk-body text-body mpk-dont-break-out">
                                    {'Pilih YA jika ingin mengaktifkan Two Factor Authentication'}
                                </p>
                                <div className="mpk-margin-N top mpk-layout">
                                    <Button
                                        primary raised
                                        className="mpk-margin-S right"
                                        onClick={() => handleReqQr()}
                                    >
                                        Ya
                                    </Button>
                                    {
                                        TfaMandatory !== true ?
                                            <Button
                                                secondary raised
                                                className="mpk-margin-S right"
                                                onClick={handleSkipTFA}
                                                disabled={TfaMandatory !== true ? false : true}
                                            >
                                                Tidak
                                            </Button>
                                            :
                                            <Button
                                                secondary raised
                                                className="mpk-margin-S right"
                                                onClick={logout}
                                                style={{
                                                }}
                                            >
                                                Back To Login
                                            </Button>
                                    }
                                    <span className="flex" />
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* </Paper> */}
            </div>
        </DialogContainer>
    );
}

export default TFAPage;