import React, { useState, useEffect } from "react";
// import DialogContainer from '../Dialog/DialogContainer';
import authService from '../../services/authService';
import LoaderInfo from '../../components/LoaderInfo';
import { DialogContainer } from 'react-md';
const SettingWidget = ({
  visible = false,
  onRequestClose,
  ...props
}) => {
  let [loading, setLoading] = useState(false);

  const renderWidget = () => {
    setLoading(true);
    const accessToken = authService.getAccessToken()
    console.log('Start Widget');
    if (window.personalSetting) {
      window.personalSetting.render(
        "setting-widget",
        {
          accessToken,
          client: "1Vh6KS76xanIa4yASV6ubHXH6bLXbBZ7yoMRrN71",
        },
        (event) => {
          if (event.type === "restart") {
          }
        }
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    if (visible) {
      setTimeout(() => {
        renderWidget();
      }, [2000]);
    } else {
      setLoading(false);
    }
  }, [visible]);
  return (
    <DialogContainer
      id="speed-boost"
      visible={visible}
      onHide={onRequestClose}
      aria-describedby="speed-boost-description"
      modal
      actions={[{
        onClick: onRequestClose,
        primary: true,
        children: 'Tutup',
      }]}
      dialogStyle={{
        width: '60vw',
        // height: '90vh'
      }}
    >
      <div id="setting-widget" className="mpk-full width height" style={{
        height: '80vh'
      }}>
        {loading && <LoaderInfo />}
      </div>
    </DialogContainer>
  );
};

export default SettingWidget;
