import React from "react";
import {
  Notification,
  LoaderInfo,
  rdxConnect,
  authService,
} from "libs/react-mpk";
import { Button } from "react-md";
import qs from "query-string";
import t from "counterpart";
import globalNotificationService from "./globalNotificationService";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import hosts from "../../services/hosts";

let socket;
class GlobalNotification extends React.Component {
  constructor() {
    super();
    this.state = {
      query: {
        page: 1,
        size: 10,
        sort: "DESC",
        sortBy: "createdAt",
      },
      hasMore: true,
      notification: {
        data: [],
        newFeeds: 0,
      },
    };
  }

  componentWillUnmount() {
    if (socket) socket.close();
  }

  componentWillMount() {
    window.addEventListener("notification-get", () => {
      console.log("notification..");
      this.initWs();
      this.getUnread();
      this.fetchData();
    });
  }

  initWs = () => {
    let query = {
      access_token: authService.getAccessToken(),
    };

    let { ws, baseUrl } = hosts.getAll().iam;

    socket = new WebSocket(
      `${ws}${baseUrl}/ws/notification?${qs.stringify(query)}`
    );

    socket.onopen = (e) => {
      console.log("[open] Notification : Connection established");
    };

    socket.onmessage = (event) => {
      if (event.data === "new notif") {
        let { notification, query } = this.state;
        notification.newFeeds++;
        notification.data = [];
        query.page = 1;
        this.setState({ notification, query }, this.fetchData);
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log("[close] Connection died");
        socket = null;
        setTimeout(this.initWs, 6000);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  };

  getUnread = async () => {
    let { notification } = this.state;
    try {
      let res = await globalNotificationService.unread();
      notification.newFeeds = res.data;
      this.setState({ notification });
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = async (newQuery = {}) => {
    let { notification, query } = this.state;
    query = _.merge(query, newQuery);

    try {
      let total = null;
      let res = await globalNotificationService.get(query, (item) => {
        console.log(item);
        if (item.data) {
          item.date = moment(item.createdAt);
          notification.data.push(item);
          this.setState({
            notification,
            hasMore: total ? notification.data.length < total : true,
          });
        }
      });

      total = Number(res.headers["x-pagination-count"]);
      if (total === 0) {
        this.setState({ hasMore: false });
      }
    } catch (error) {
      this.setState({ hasMore: false });
      console.log(error);
    }
  };

  onMessage = (item) => {
    setTimeout(() => {
      item.date = moment(item.createdAt.$date);
      let { notification } = this.state;
      notification.data.unshift(item);
      notification.newFeeds++;
      this.setState({ notification });
    }, 1000);
  };

  onClickNotification = async () => {
    let { notification } = this.state;
    notification.newFeeds = 0;
    this.setState({ notification });
    await globalNotificationService.unreadAll();
  };

  download = async (item) => {
    try {
      await globalNotificationService.download(item?.data);
    } catch (error) {
      this.props.toastActions.izi(
        t.translate("word.failed"),
        error.message[this.props.global.localeCode],
        "warning"
      );
    }
  };

  render() {
    let { notification, hasMore } = this.state;
    console.log(notification);
    return (
      <Notification
        newFeeds={notification.newFeeds}
        onClick={this.onClickNotification}
      >
        {/* <Button
          primary
          raised
          iconClassName="mdi mdi-reload"
          onClick={() => this.fetchData({ page: 1 })}
        >
          Reload
        </Button> */}
        <InfiniteScroll
          pageStart={1}
          loadMore={(page) => this.fetchData({ page })}
          hasMore={hasMore}
          loader={<LoaderInfo />}
          useWindow={false}
        >
          {notification.data.map((d, i) => {
            let actions = [
              <Button
                primary
                swapTheming
                iconClassName="mdi mdi-download"
                onClick={() => this.download(d)}
              >
                {`${t.translate("word.download")}`}
              </Button>,
            ];

            return (
              <Notification.Item
                key={`notification-item-${i}`}
                message={`${d.notification} (${d.data})`}
                date={d.date}
                actions={actions}
              />
            );
          })}
        </InfiniteScroll>
      </Notification>
    );
  }
}

export default rdxConnect(GlobalNotification);
