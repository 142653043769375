import React from 'react';
import { DataTable, TableHeader, TableBody, TableRow, TableColumn } from 'react-md';
import t from 'counterpart';
import numeral from 'numeral';
import './Invoice.scss';

const Invoice = ({baseId, title, data, showQuantity, additional, className}) => {
  let keys = showQuantity ? [
    {label:'Item', value:'item'},
    {label:'Description', value: 'description'}, 
    {label:'Qty', value:'quantity'},
    {label:'Unit', value:'unit'},
    {label:t.translate('word.price'), value: 'price'},
    {label:t.translate('word.total'), value: 'total'}
  ] : [
    {label:'Item', value:'item'}, 
    {label:'Description', value: 'description'},
    {label:t.translate('word.price'), value: 'price'}
  ]

  let totalPrice = () => {
    let total = 0;
    if(data.length > 0){
      let sum = data.map( d => (d.price)).reduce((a,b) => (a+b));
      total += sum
    }
    if(additional.length > 0)
      total += additional.map( d => (d.price)).reduce((a,b) => (a+b));
    return numeral(total).format('0,0');
  }

  let subtotal = () => {
    let total = 0;
    if(data.length > 0){
      let sum = data.map( d => (d.price)).reduce((a,b) => (a+b));
      total += sum
    }

    return numeral(total).format('0,0');
  }

  return (
    <div className={`mpk-invoice ${className}`}>
      {title ? <h2>{title}</h2> : null }
      <DataTable
        baseId={baseId}
        selectableRows={false}
      >
        <TableHeader>
          <TableRow>
            { keys.map( key => (
              <TableColumn key={key.value}>{key.label}</TableColumn>
            ))} 
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map( (d, i) => (
            <TableRow key={`${baseId}-data-${i}`}>
              {keys.map( key => (
                <TableColumn key={`${key.value}-${i}`} numeric={key.value === 'price'}>
                  {key.value === 'price' ? numeral(d[key.value]).format('0,0') : d[key.value]}
                </TableColumn>
              ))}
            </TableRow>
          ))}
          <TableRow className="sub">
            {keys.map( (key, ik) => (
                <TableColumn key={`total-col-${key.value}`} numeric={key.value === 'price'}>
                  <strong>{ik === keys.length - 2 ? 'Subtotal' : (key.value === 'price' ? subtotal() : '')}</strong>
                </TableColumn>
              ))}
          </TableRow>
          {additional.map((ad, i) => (
            <TableRow className="additional">
              {keys.map( key => (
                <TableColumn key={`additional-${key.value}-${i}`} numeric={key.value === 'price'}>
                  {key.value === 'price' ? numeral(ad[key.value]).format('0,0') : ad[key.value]}
                </TableColumn>
              ))}
            </TableRow>
          ))}
          <TableRow className="total">
            {keys.map( (key, ik) => (
                <TableColumn key={`total-col-${key.value}`} numeric={key.value === 'price'}>
                  <strong>{ik === keys.length - 2 ? 'Total' : (key.value === 'price' ? totalPrice() : '')}</strong>
                </TableColumn>
              ))}
          </TableRow>
        </TableBody>
      </DataTable>
    </div>
  )
}

Invoice.defaultProps = {
  data:[],
  additional:[],
  baseId:'mpk-invoice',
  title:null,
  showQuantity: true, 
  className:''
}

export default Invoice;