import React from 'react';
import ImageThumbnail from '../ImageThumbnail';
import { FileInput } from 'react-md';

const ImagePicker = (props) => (
  <div className="flex-none mpk-layout column flex-none mpk-margin-N margin-bottom" style={{width: 160}}>   
    <ImageThumbnail 
      image={{
        src: props.src,
        caption:''
      }} 
      defaultIconClassName={props.iconClassName}
      style={{
        width: 160,
        height: 160
      }}
    />
    <FileInput 
        id="image-input-3" primary
        accept="image/*" name="images" 
        onChange={props.onChange}
        className="mpk-margin-S top"
        label={props.placeholder}
    />
  </div>
)

ImagePicker.defaultProps = {
  onChange: () => {},
  iconClassName: 'mdi mdi-image',
  placeholder: 'Select',
  src: null
}

export default ImagePicker;