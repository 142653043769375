import React from 'react';
import { Button, CircularProgress } from 'react-md';
import { numberService, httpService, rdxConnect } from '../../../libs/react-mpk';
import t from 'counterpart';
import * as service from '../service';

class Profile extends React.Component{
  state = {
    isLoading: true
  }

  componentDidMount(){
    this.props.tmpObjectActions.setProperties({userBalance: null})
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({isLoading: true})
    let { profileOnly } = this.props.tmpObject;
    try {
      let res = await service.getUserBalance();
      let hasPin = res.headers['x-pin'] === 'true';
      
      this.props.tmpObjectActions.setProperties({userBalance: res.data});
      this.props.tmpObjectActions.setProperties({hasPin});
      if(!hasPin && !profileOnly){
        window.dispatchEvent(new CustomEvent('pin', {detail:{eventType:'setup'}}));
      }
      this.setState({isLoading: false})
    } catch (error) {
      if(error){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          httpService.utils.parseErrorMessage(error, this.props.global.localeCode),
          'warning'
        )
      }
    }
  }

  showDetails = () => {
    let { parentOrigin } = this.props.tmpObject;
    window.parent.postMessage({
      eventName: 'custom-event-ub',
      data: {
        type: 'on-show-details-ub'
      }
    }, parentOrigin)
  }

  render(){
    let { isLoading } = this.state;
    let { userBalance } = this.props.tmpObject;
    return(
      <div 
        className="mod-profile mpk-full height mpk-padding-N padding-all mpk-border thin solid dark border-top border-bottom mpk-layout align-center justify-between"
      >
        <div className="mpk-layout align-center">
          <img 
            src="/static/images/icon-saldo.svg"
            alt="icon-saldo"
            style={{
              width: 56,
              height: 56
            }}
          />
          <div className="mpk-layout column mpk-margin-S margin-left">
            <span className="mpk-font-size-S">
              {t.translate('column.balance').toUpperCase()}
            </span>
            <div className="mpk-font-size-L mpk-color-primary mpk-font-weight-B mpk-layout align-center" style={{height: 24}}>
              {isLoading ? <CircularProgress scale={.6} style={{margin: '0'}}/> : numberService.parseNumber(userBalance.currentBalance)}
            </div>
            <div className="mpk-font-size-S">
              <span 
                className="mpk-link"
                onClick={() => this.fetchData()}
              >
                Reload
              </span>
              <span className="mpk-margin-XS margin-left margin-right">|</span>
              <a 
                href="https://kbs.pajakku.com/documentation/USER-BALANCE-DOC" 
                target="blank"
                className="mpk-link"
              >
                {t.translate('sentence.info.learnMore')}
              </a>
            </div>
          </div>
        </div>
        { this.props.showDetailsButton && (
          <Button 
            icon iconClassName="mdi mdi-arrow-right"
            className="mpk-padding-NONE padding-all"
            onClick={this.showDetails}
            style={{
              width: 24,
              height: 24
            }}
          />
        )}
      </div>
    )
  }
}

Profile.defaultProps = {
  showDetailsButton: true
}

export default rdxConnect(Profile);