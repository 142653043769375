import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { httpService, rdxConnect } from 'libs/react-mpk';
import * as service from './service';

class RefundApproval extends React.Component{
  render(){
    const hasPermission = this.props.auth.hasPermission('PUT:/refund_approval_definition/{id}','POST:/refund_approval_definition');

    return(
      <Wrapper.Form
        title={t.translate('routes.setting.application.formTitle')}
        columnValidations={['name', 'code']}
        defaultFormData={() => ({
          name: '',
          code: '',
          description: '',
          isDefault: true,
          isUseApproval: false,
          isUseChecking: false
        })}
        definitions={[
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.name'),
            key: 'name',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.code'),
            key: 'code',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.description'),
            key: 'description',
            rows: 2,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.CHECKBOX,
            label: 'Default',
            key: 'isDefault',
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.CHECKBOX,
            label: 'Use Approval',
            key: 'isUseApproval',
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.CHECKBOX,
            label: 'Use Checking',
            key: 'isUseChecking',
            disabled: !hasPermission,
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (formData, isEdit, callback) => {
          try {
            let res = isEdit ? await service.put(formData.id, formData) : await service.post(formData);
            if(isEdit){
              this.props.tableActions.updateDataItemWithSelector({id:res.data.id},res.data);
            }else{
              this.props.tableActions.addDataItem(res.data);
            }
            callback(false, t.translate('sentence.info.savedItem'));
          } catch (error) {
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
          }
        }}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(RefundApproval);