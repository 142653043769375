/**
 * Created by dwiargo on 12/6/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import rdxConnect from '../../hoc/rdxConnect';
import { DialogContainer, Button, LinearProgress } from 'react-md';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import './Dialog.scss';

const defaultState = () => ({
  onProgress:false,
  isError:false,
  errorMessage:''
});

class DialogForm extends Component {
  constructor(){
    super();
    this.state = defaultState()
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(this.props.showSubmit){
      this.setState({onProgress:true, isError:false});
      this.props.onSubmit((isError, message, closeDialog = true) => {
        if(isError){
          this.setState({
            onProgress: false,
            isError:true,
            errorMessage:message
          });

          this.props.toastActions.izi(
            counterpart.translate('word.failed'),
            message,
            'warning'
          )
          
        } else {
          if(closeDialog) this.props.onCancel();
          if(message){
            this.props.toastActions.izi(
              counterpart.translate('word.success'),
              message,
              'success'
            )
          }
          this.setState(defaultState());
        }
      })
    }
  };

  onCancel = () => {
    this.setState(defaultState());
    this.props.onCancel();
  };

  render(){
    let {visible, translate, title, size, disabledForm, children, relativeContent, submitLabel, cancelLabel, showSubmit=true, showCancel=true, ...otherProps } = this.props;
    let {onProgress} = this.state;
    onProgress = onProgress || this.props.onProgress;
    let formClassName = 'form'//'form flex ' + (disabledScroll ? '' : 'mpk-content');


    return(
      <DialogContainer
        id="mpk-dialog-form"
        className={"mpk-dialog dialog-form "+ (size ? "size-"+size : "") + " " + (relativeContent ? "relative-content" : "")}
        onHide={()=>{}}
        onShow={()=>{
          this.setState({
            onProgress:false,
            isError:false,
            errorMessage:''
          })
        }}
        focusOnMount={false}
        title={translate ? counterpart.translate(title) : title}
        visible={visible}
        actions={showSubmit || showCancel ? [
          <Button flat secondary onClick={this.onCancel} disabled={onProgress} style={{display: showCancel ? 'inherit' : 'none'}}>
            {cancelLabel ? cancelLabel : (<Translate content={'word.cancel'}/>)}
          </Button>,
          <Button 
            className={showSubmit ? '' : 'mpk-hide'}
            flat primary 
            onClick={(e) => {
              if(disabledForm) this.onSubmit(e);
              else {
                document.getElementById(`${this.props.baseName}-submit`).click();
              }
            }} 
            disabled={onProgress} style={{display: showSubmit ? 'inherit' : 'none'}}>
            {submitLabel ? submitLabel : (<Translate content={'word.save'}/>)}
          </Button>
        ] : []}
        autosizeContent
        {...otherProps}
      >
        {onProgress ? (
          <LinearProgress id="mpk-dialog-form-progress"/>
        ) : (null)}
        <div className={relativeContent ? "" : "mpk-layout column fill"}>
          {/*isError ? (
            <div className="mpk-padding-N right left mpk-position sticky" style={{zIndex:10}}>
              <StatusInfo
                status="error"
                message={errorMessage}
                global={global}
                />
            </div>
          ) : (null)*/}
          {disabledForm ? (
            <div style={this.props.formStyle} className={formClassName}>
              {children}
              <button type="submit" disabled={onProgress} style={{opacity:0}}/>
            </div>
          ):(
            <form 
              style={this.props.formStyle}
              className={formClassName} 
              onSubmit={this.onSubmit}
            >
              {children}
              <button 
                type="submit" disabled={onProgress} style={{opacity:0}}
                id={`${this.props.baseName}-submit`}
              />
            </form>
          )}
        </div>
      </DialogContainer>
    )
  }
}

DialogForm.defaultProps = {
  showSubmit: true,
  baseName: 'mpk-dialog-form'
}

DialogForm.propTypes = {
  title:PropTypes.any.isRequired,
  visible:PropTypes.bool.isRequired,
  onSubmit:PropTypes.func.isRequired,
  onCancel:PropTypes.func.isRequired,
  submitLabel:PropTypes.string,
  cancelLabel:PropTypes.string,
  translate:PropTypes.bool,
  disabledScroll:PropTypes.bool,
  disabledForm:PropTypes.bool,
  relativeContent:PropTypes.bool,
  style:PropTypes.object
};


export default rdxConnect(DialogForm);
