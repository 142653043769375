import React from 'react';
import { merge } from 'lodash';
import * as service from '../service';
import { httpService, rdxConnect, Pagination, LoaderInfo, NotFoundData, numberService } from 'libs/react-mpk';
import t from 'counterpart';
import { Button, DataTable, TableBody, TableHeader, TableColumn, TableRow } from 'react-md';
import moment from 'moment';
import TransactionDetails from '../../Main/User/Transaction/TransactionDetails';

class Transaction extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      isLoading: false,
      showDetails: false,
      selectedItem: null,
      query: {
        page: 1,
        size: 20,
        keyword: '',
        column: '',
        total: 0,
        sort: 'DESC',
        sortBy: 'transactionDateTime'
      }
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async (newQuery = {}) => {
    let { query } = this.state;
    query = merge(query, newQuery);
    query.total = 0;
    this.setState({isLoading: true, query})
    try {
      let data = [];
      let res = await service.getTransaction(query, item => {
        data.push(item);
        this.setState({data})
      });

      query.total = Number(res.headers['x-pagination-count']);
      this.setState({query, isLoading: false});
    } catch (error) {
      this.props.toastActions.izi(
        t.translate('word.failed'),
        httpService.utils.parseErrorMessage(error, this.props.global.localeCode),
        'warning'
      )
    }
  }

  render(){
    let { query, isLoading, data } = this.state;
    console.log("🚀 ~ file: Transaction.js ~ line 59 ~ Transaction ~ render ~ data", data)
    let { asMobile } = this.props.tmpObject
    let columns = [
      {
        label: '#',
        value: (d, i) => asMobile ? (
          null
        ) : (
          (i+1)+(query.size*(query.page-1))
        )
      },
      {
        label: t.translate('column.transactionDateTime'),
        value: (d) => asMobile ? (
          <div 
            className="mpk-font-size-S mpk-font-color-D3"
            style={{
              width: '100% !important',
            }}
          >
            {moment(d.transactionDateTime).format('lll')}
          </div>
        ) : (moment(d.transactionDateTime).format('lll'))
      },
      {
        label: t.translate('column.transaction'),
        value: (d) => (
          <div style={{width: '100%'}}>
            <div className={`mpk-font-weight-B ${asMobile ? 'mpk-font-size-L' : ''}`}>
              {d.transactionType.name}
            </div>
          </div>
        )
      },
      {
        label: t.translate('column.status'),
        value: (d) => {
          let ub = d.userBalancePayment || d.userBalanceTopup || d.userBalanceRefund;
          return (
            <div className="mpk-padding-S padding-top padding-bottom mpk-align-center mpk-max-width-M">
              <div className={`mpk-status ${ub.statusMessage.toLowerCase().match('success') ? 'progress' : 'idle outline'} mpk-full width`}>
                {ub.statusMessage}
              </div>
              {/* {ub.description && (
                <div className="mpk-font-size-S mpk-font-color-D3 mpk-margin-S margin-top">
                  {ub.description}
                </div>
              )} */}
            </div>
          )
        }
      },
      {
        label: t.translate('column.amount'),
        value: (d) => asMobile ? (
          <div className="mpk-align-right flex">
            {numberService.parseNumber(d.amount)}
          </div>
        ) : (numberService.parseNumber(d.amount))
      },
      {
        label: t.translate('word.actions'),
        value: (d) => (
          <Button 
            iconClassName="mdi mdi-eye" icon
            onClick={() => this.setState({showDetails: true, selectedItem: d})}
          />
        )
      },
    ]

    const table = (
      <DataTable
        className="flex-none"
        baseId="ub-transaction-table"
        selectableRows={false}
      >
        <TableHeader>
          <TableRow>
            {columns.map(col => (
              <TableColumn
                key={`header-transaction-list-${col.label}`}
              >
                {col.label}
              </TableColumn>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((d,i) => (
            <TableRow key={`body-transaction-row-${i}`}>
              {columns.map(col => (
                <TableColumn key={`body-transaction-row-${i}-col-${col.label}`}>
                  {col.value(d, i)}
                </TableColumn>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </DataTable>
    )

    const mobileTable = data.map((d, i) => (
      <div 
        key={`transaction-${i}`}
        className="mpk-padding-N padding-all mpk-full full-width mpk-layout align-center justify-between wrap mpk-border border-bottom solid dark"
      >
        {columns.map((col, ci) => (
          col.value(d, i)
        ))}
      </div>
    ))
    

    return(
      <div className="mpk-layout column mpk-full viewport-width height mpk-animation slide-in">
        <div 
          className="mpk-border thin solid border-bottom dark mpk-padding-N padding-all mpk-layout align-center justify-between"
          style={{
            height: 48
          }}
        >
          <div className="mpk-layout align-center mpk-position">
            {/* <SearchField 
              withBorder={false}
              onSubmit={keyword => this.fetchData({keyword, page: 1})}
            /> */}
            <Pagination 
              {...query}
              onChange={ page => this.fetchData({page})}
            />
          </div>
          <div className="mpk-layout align-center">
            <Button 
              icon iconClassName="mdi mdi-reload"
              primary onClick={() => this.fetchData()}
              mini style={{width: 40, height: 40}}
              className="mpk-margin-S margin-right"
            />
          </div>
        </div>
        { isLoading ? <LoaderInfo/> : (
          query.total === 0 ? <div className="mpk-padding-M padding-top padding-bottom"><NotFoundData/></div> : (
            asMobile ? (
              <div className="mpk-content">
                {mobileTable}
              </div>
            ) : table
          )
        )}
        <TransactionDetails
          visible={this.state.showDetails}
          onCancel={() => this.setState({selectedItem: null, showDetails: false})}
          item={this.state.selectedItem}
        />
      </div>
    )
  }
}

export default rdxConnect(Transaction);