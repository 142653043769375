/**
 * Created by dwiargo on 3/5/18.
 */

import * as types from './actionTypes';

export const setProperties = (properties) => {
  return {
    type:types.AUTH_SET_PROPERTIES,
    properties
  }
};