import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { rdxConnect, numberService } from 'libs/react-mpk';
import moment from 'moment';

class TransactionDetails extends React.Component{
  render(){
    let { item } = this.props;
    console.log("🚀 ~ file: TransactionDetails.js ~ line 10 ~ TransactionDetails ~ render ~ item", item)
    return(
      <Wrapper.Form
        title={`${t.translate('routes.userBalance.transaction.detailsTitle')}` }
        columnValidations={[]}
        dialogStyle={{
          width: '100%',
          maxWidth: 480
        }}
        definitions={[
          {
            inputType: Wrapper.Form.types.DIVIDER,
            label: t.translate('column.account')
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.accountNo'),
            getValue: () => (item ? item.userBalanceAccount.accountNo : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.username'),
            getValue: () => (item ? item.userBalanceAccount.username : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.DIVIDER,
            label: t.translate('column.transactionType')
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.dateTime'),
            getValue: () => (item ? moment(item.transactionDateTime).format('lll') : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.transactionType'),
            getValue: () => (item && item.transactionType ? item.transactionType.name : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: 'D/C',
            getValue: () => (item && item.transactionType ? item.transactionType.debitCredit : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.DIVIDER,
            label: t.translate('column.transaction')
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.amount'),
            getValue: () => (item ? numberService.parseNumber(item.amount) : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.openBalance'),
            getValue: () => (item ? numberService.parseNumber(item.openBalance) : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.closeBalance'),
            getValue: () => (item ? numberService.parseNumber(item.closeBalance) : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.status'),
            getValue: () => (item && (item.userBalanceTopup || item.userBalancePayment || item.userBalanceRefund) ? (item.userBalanceTopup || item.userBalancePayment || item.userBalanceRefund).statusMessage : '-'),
            readOnly: true
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.description'),
            getValue: () => (item && (item.userBalanceTopup || item.userBalancePayment || item.userBalanceRefund) ? (item.userBalanceTopup || item.userBalancePayment || item.userBalanceRefund).description : '-'),
            readOnly: true
          },
           {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.referenceNo'),
            getValue: () => (item ? item.referenceNo : '-'),
            readOnly: true
          },
        ]}
        onBeforeChange={(key, value) => {
          if(key === 'code') value = value.toUpperCase().replace(/ /g,'');
          if(key === 'transactionFee') value = value.replace(/\D/g, '');
          return value;
        }}
        // onSubmit={async (formData, isEdit, callback) => {
        //   try {
        //     let res = isEdit ? await service.put(formData.id, formData) : await service.post(formData);
        //     this.props.tableActions.addDataItem(res.data);
        //     callback();
        //   } catch (error) {
        //     callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
        //   }
        // }}
        showSubmit={false}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(TransactionDetails);