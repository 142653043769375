/**
 * Created by dwiargo on 12/7/17.
 */

import React,{ Component } from 'react';
import { MenuButton, FontIcon, Button } from 'react-md';

class TableItemAction extends Component {
  render() {
    let { item } = this.props; 
    let _itemActions = this.props.itemActions.filter(d => {
      let show = typeof d.show === 'boolean' ? d.show : (
        typeof d.show === 'function' ? d.show(item) : true
      );
      return show;
    }).map((d) => {
      if (d.label === 'divider') {
        return {divider: true}
      } else {
        return {
          // iconClassName:d.iconClassName,
          leftIcon: <FontIcon iconClassName={d.iconClassName}/>,
          primaryText: d.label,
          onClick: () => {
            if(d.confirmation){
              let confirmation = d.confirmation;
              confirmation.visible = true;
              confirmation.action = d.onClick;
              this.props.tableActions.setProperties({
                confirmation:confirmation,
                selectedItem:item
              })
            } else {
              d.onClick(item);
            };
          }
        }
      }
    });

    return (
      <div>
        {_itemActions.length > 1 ? (
          <MenuButton
            id="mpk-table-action-item"
            icon
            menuItems={_itemActions}
            listClassName="tables__with-menus__kebab-list"
            >
            <FontIcon>more_vert</FontIcon>
          </MenuButton>
        ) : _itemActions.length > 0 ? (
          <Button
            icon
            iconChildren={_itemActions[0].leftIcon}
            onClick={() => _itemActions[0].onClick(this.props.item)}
            />
        ) : <div style={{textAlign: 'center'}}>-</div>}
      </div>
    )
  }
}

export default TableItemAction;