/**
 * Created by dwiargo on 11/29/17.
 */

export const INIT_USER = "INIT_USER";

export const GLOBAL_SET_PROPERTIES = "GLOBAL_SET_PROPERTIES";
export const GLOBAL_TOGGLE_PROPERTY = "GLOBAL_TOGGLE_PROPERTY";

export const TABLE_SET_DATA = "TABLE_SET_DATA";
export const TABLE_SET_COLUMNS = "TABLE_SET_COLUMNS";
export const TABLE_SET_PROPERTIES = "TABLE_SET_PROPERTIES";
export const TABLE_UPDATE_COLUMN = "TABLE_UPDATE_COLUMN";
export const TABLE_UPDATE_PARAMS = "TABLE_UPDATE_PARAMS";
export const TABLE_ADD_DATA_ITEM = "TABLE_ADD_DATA_ITEM";
export const TABLE_DELETE_DATA_ITEM = "TABLE_DELETE_DATA_ITEM";
export const TABLE_DELETE_DATA_ITEM_WITH_SELECTOR = "TABLE_DELETE_DATA_ITEM_WITH_SELECTOR";
export const TABLE_DELETE_DATA_ITEMS = "TABLE_DELETE_DATA_ITEMS";
export const TABLE_UPDATE_DATA_ITEM = "TABLE_UPDATE_DATA_ITEM";
export const TABLE_UPDATE_DATA_ITEM_WITH_SELECTOR="TABLE_UPDATE_DATA_ITEM_WITH_SELECTOR";
export const TABLE_REPLACE_DATA_ITEM = "TABLE_REPLACE_DATA_ITEM";
export const TABLE_DELETE_SELECTED_DATA_ITEMS = "TABLE_DELETE_SELECTED_DATA_ITEMS";
export const TABLE_SELECT_DATA_ITEM = "TABLE_SELECT_DATA_ITEM";
export const TABLE_DESELECT_DATA_ITEM = "TABLE_DESELECT_DATA_ITEM";
export const TABLE_RESET_ALL = "TABLE_RESET_ALL";
export const TABLE_RESET = "TABLE_RESET";
export const TABLE_RESET_PARAMS = "TABLE_RESET_PARAMS";
export const TABLE_RELOAD_DATA = "TABLE_RELOAD_DATA";

export const AUTH_SET_PROPERTIES = "AUTH_SET_PROPERTIES";

export const TOAST_ADD = "TOAST_ADD";
export const TOAST_DISMISS = "TOAST_DISMISS";

export const DIALOG_CONFIRM_SHOW = "DIALOG_CONFIRM_SHOW";
export const DIALOG_ALERT_SHOW = "DIALOG_ALERT_SHOW";
export const DIALOG_HIDE = "DIALOG_HIDE";

export const TMP_OBJECT_SET_PROPERTIES = "TMP_OBJECT_SET_PROPERTIES";
export const TMP_OBJECT_RESET = "TMP_OBJECT_RESET";