export const themes = {
  PAJAKKU:'ad2700c315f98a65120c4a8503860c1b',
  SOBATPAJAK: '66cebbca847243f422fde45a47cc619b'
}

export const portals = {
  'ad2700c315f98a65120c4a8503860c1b':{
    host: 'https://private.pajakku.com',
    baseUrl: '/website_cms_api',
    channel: 'website_portal',
    clientId: 'l9H1ginFwcDhiAMwx5k9gOKZAd89HHamoHkRwZHJ'
  },
  '66cebbca847243f422fde45a47cc619b':{
    host: 'https://private.sobatpajak.com',
    baseUrl: '/website_cms_api',
    channel: 'website_portal',
    clientId: 'ZzUXG37XUbxE9HCSgK4Z0oIxf3XgbyzWUwTJmnMQ'
  },
}