import React from 'react';
import { Paper } from 'react-md';
import './RatePlanCard.scss';
import RatePlanCardImg from './RatePlanCardImg';
import numeral from 'numeral';

const RatePlanCard = ({ 
  ratePlan, img, productPackage, style, className, footer, showCharge, onClick, children, name, header,
  showImage=true 
}) => (
  <Paper 
    className={`mpk-rate-plan-card mpk-layout column ${className} ${onClick ? 'clickable' : ''}`}
    zDepth={1} 
    style={style} 
    onClick={() => {
      if(onClick) onClick();
    }}
  >
    { showImage ? <RatePlanCardImg src={img || productPackage.icon}/> : null } 
    {header ? (
      <div className="mpk-padding-NS padding-top padding-left padding-right">{header}</div>
    ) : null}
    <div className="mpk-padding-NS padding-all flex">
      <div className="mpk-font-size-L mpk-font-weight-B mpk-font-color-primary">{name && name !== '' ? name : productPackage.name}</div>
      <div className="mpk-font-color-D3">{`${name && name !== '' ? `${productPackage.name} | ` : ''}${ratePlan.name || ''}`}</div>
      { showCharge && ratePlan.charges ? (
        <div className="charges mpk-padding-NS padding-top mpk-border dotted dark top mpk-margin-NS margin-top mpk-font-color-D2">
          IDR <span>
            {(() => {
              let prices = ratePlan.charges.map( d => d.pricingAmount);
              return prices.length > 0 ? numeral(prices.reduce((a,b) => (a+b))).format('0,0') : 0;
            })()}
          </span>
        </div>
      ) : null}
    </div>
    {children ? (
      <div className="mpk-padding-NS padding-all">{children}</div>
    ) : null}
    {footer ? (
      <footer 
        className="md-dialog-footer flex-none mpk-padding-NS padding-left padding-right"
        style={{
          padding: 8
        }}
      >
        {footer}
      </footer>
    ) : null}
  </Paper>
)

RatePlanCard.defaultProps = {
  img: null,
  ratePlan:{},
  productPackage:{},
  style:{},
  className: '',
  footer: null,
  showCharge: true,
  onClick: null
}

export default RatePlanCard;