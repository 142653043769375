/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo-pajakku.svg';
import logoLight from '../../assets/logo-pajakku-light.svg';

import logoSobatpajak from '../../assets/logo-sobatpajak.svg';
import logoSobatpajakLight from '../../assets/logo-sobatpajak-light.svg';

import env from '../../config/env';

const LogoContainer = ({style, light, srcLogo, srcLogoLight}) => (
  <img
    src={ light ? srcLogoLight : srcLogo}
    alt="logo"
    style={style}
  />
)

class Logo extends Component {
  render(){
    let { light, style, subname, containerStyle, className='' } = this.props;
    return(
      <div 
        className={`mpk-logo ${light ? 'light' : ''} mpk-layout align-center ${className}`} 
        style={containerStyle}
      >
        { env.theme === env.themes.PAJAKKU ? <LogoContainer style={style} {...{light, srcLogo: logo, srcLogoLight: logoLight}}/> : null }
        { env.theme === env.themes.SOBATPAJAK ? <LogoContainer style={style} {...{light, srcLogo: logoSobatpajak, srcLogoLight: logoSobatpajakLight}}/> : null }
        { subname ? (
          <div className="mpk-margin-N left mpk-font-size-NS" style={{opacity:.72}}>{subname}</div>
        ) : (null)}
      </div>
    )
  }
}

Logo.propTypes = {
  style:PropTypes.object,
  light:PropTypes.bool,
}

export default Logo;