import React from 'react';
import t from 'counterpart';
import detailsWrapper from '../../../../components/detailsWrapper';
import { Commandbar } from '../../../../libs/react-mpk';

import List from './List';
import Refund from './Refund';
import TopUp from './TopUp';
import Payment from './Payment';
import Refunded from './Refunded';

class TransactionWrapper extends React.Component{
  render(){
    return(
      <div className="flex mpk-layout column">
        <Commandbar
          title={t.translate('routes.userBalance.transaction.title')}
          breadcrumbs={[
            t.translate('routes.userBalance.title')
          ]}
        />
        {this.props.children}
      </div>
    )
  }
}

export default detailsWrapper((props) => ({
  tabs:[
    {
      label: t.translate('column.transaction'),
      key: 'list',
      resourceUri: 'GET:/transaction',
      component: <List/>,
    },
    {
      label: t.translate('column.refund'),
      key: 'refund',
      resourceUri: 'GET:/transaction/refund',
      component: <Refund/>,
    },
    {
      label: t.translate('column.refunded'),
      key: 'refunded',
      resourceUri: 'GET:/transaction/refunded',
      component: <Refunded />,
    },
    {
      label: t.translate('column.topup'),
      key: 'top-up',
      resourceUri: 'GET:/transaction/top_up',
      component: <TopUp/>,
    },
    {
      label: t.translate('column.payment'),
      key: 'payment',
      resourceUri: 'GET:/transaction/payment',
      component: <Payment/>,
    }
  ],
  onTabChange: (tab) => {
    props.router.history.push(`/main/user/transaction/${tab.key}`)
  },
  onInit: async (callback) => {
    setTimeout(callback)
  }
}))(TransactionWrapper);