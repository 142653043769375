import numeral from 'numeral';
import _ from 'lodash';

export default {
  parseNumber: value => {
    return numeral(value).format('0,0').replace(/\,/g, '&').replace(/\./g, ',').replace(/&/g, '.')
  },
  unparseNumber: value => {
    return Number(String(value).replace(/\,/g, ''))
  },
  convertSize:(value, from='byte') => {
    const units = ['byte', 'KB', 'MB', 'GB', 'TB'];
    const divider = 1024;
    const idx = units.indexOf(from) >= 0 ? units.indexOf(from) : 0;
    
    let conv = {
      v:value,
      idx:idx
    };

    for(let i = idx ; i < units.length ; i ++){
      if(conv.v < divider){
        conv.idx = i
        break;
      } else {
        conv.v /= divider
      }
    }

    return `${numeral(conv.v).format('0,0.00')} ${units[conv.idx]}`;
  },
  terbilang:(value) => {
    let numbers = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan'];
    let suffix = ['Belas', 'Puluh', 'Ratus', 'Ribu', 'Juta', 'Milyar', 'Triliun'];
    let result = '';

    let getSuffix = (number) => {
      number = Number(number);
      if(number >= Math.pow(10,12)) return suffix[6];
      else if(number >= Math.pow(10,9)) return suffix[5];
      else if(number >= Math.pow(10,6)) return suffix[4];
      else if(number >= Math.pow(10,3)) return suffix[3];
      else if(number >= Math.pow(10, 2)) return suffix[2];
      else if(number >= 20 || number === 10) return suffix[1];
      else if(number > 10) return suffix[0];
      else return '';
    }

    const runGroup = (val) => {
      let numArr = String(Number(val)).split('');
      let _numArr = _.clone(numArr);
      for( let i = 0 ; i < numArr.length ; i++ ){
        let n = numArr[i];
        if(n > 0){
          let _n = _numArr.toString().replace(/,/g, '');
          let _suffix = getSuffix(_n);
          let isBelasan = _suffix === suffix[0] ? true : false;
          let _inWord = isBelasan ? numbers[Number(numArr[i+1])] : numbers[Number(n)];
          result += `${_inWord} ${_suffix} `;
          _numArr = _numArr.slice(1, _numArr.length);
          if(isBelasan) break;
        }
      }
    }

    let groups = numeral(value).format('0,0').split(',');
    for(let n = 0 ; n < groups.length ; n++){
      if(String(groups[n]).length === 2 && Number(groups[n][0]) === 1){
        let idx = Number(groups[n][0]);
        result += `${numbers[idx]} ${getSuffix(groups[n])}`;
      } else runGroup(groups[n]);
      
      if(groups[n+1]){
        let _groups = [groups[n]];
        for( let _n = n + 1 ; _n < groups.length ; _n++){
          _groups.push(groups[_n]);
        }
        result += ` ${getSuffix(_groups.toString().replace(/,/g, ''))} `;
      }
    }
    result = result.replace(/ +/g, ' ');
    for(let replacer of [
      {
        selector: `${numbers[1]} ${suffix[1]}`,
        with: 'Sepuluh',
        startOnly: false
      },
      {
        selector: `${numbers[1]} ${suffix[2]}`,
        with: 'Seratus',
        startOnly: false
      },
      {
        selector: `${numbers[1]} ${suffix[3]}`,
        with: 'Seribu',
        startOnly: true
      }
    ]){
      result = replacer.startOnly ? ( result.startsWith(replacer.selector) ? result.replace(replacer.selector, replacer.with) : result) :  result.replace(new RegExp(replacer.selector, 'g'), replacer.with);
    }

    return result
  }
}