/**
 * Created by dwiargo on 1/24/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AppLogoStatic.scss';

import developerPM_L from '../../assets/appLogo/developer-picturemark-l.svg';
import developerLT_L from '../../assets/appLogo/developer-logotype-l.svg';
import developerPM_D from '../../assets/appLogo/developer-picturemark-d.svg';
import developerLT_D from '../../assets/appLogo/developer-logotype-d.svg';
import efilingPM_L from '../../assets/appLogo/efiling-picturemark-l.svg';
import efilingLT_L from '../../assets/appLogo/efiling-logotype-l.svg';
import efilingPM_D from '../../assets/appLogo/efiling-picturemark-d.svg';
import efilingLT_D from '../../assets/appLogo/efiling-logotype-d.svg';
import epptPM_L from '../../assets/appLogo/eppt-picturemark-l.svg';
import epptLT_L from '../../assets/appLogo/eppt-logotype-l.svg';
import epptPM_D from '../../assets/appLogo/eppt-picturemark-d.svg';
import epptLT_D from '../../assets/appLogo/eppt-logotype-d.svg';
import halonaPM_L from '../../assets/appLogo/halona-picturemark-l.svg';
import halonaLT_L from '../../assets/appLogo/halona-logotype-l.svg';
import halonaPM_D from '../../assets/appLogo/halona-picturemark-d.svg';
import halonaLT_D from '../../assets/appLogo/halona-logotype-d.svg';
import ebillingPM_L from '../../assets/appLogo/ebilling-picturemark-l.svg';
import ebillingLT_L from '../../assets/appLogo/ebilling-logotype-l.svg';
import ebillingPM_D from '../../assets/appLogo/ebilling-picturemark-d.svg';
import ebillingLT_D from '../../assets/appLogo/ebilling-logotype-d.svg';
import h2hPM_L from '../../assets/appLogo/h2h-picturemark-l.svg';
import h2hLT_L from '../../assets/appLogo/h2h-logotype-l.svg';
import h2hPM_D from '../../assets/appLogo/h2h-picturemark-d.svg';
import h2hLT_D from '../../assets/appLogo/h2h-logotype-d.svg';
import tarraPM_L from '../../assets/appLogo/tarra-picturemark-l.svg';
import tarraLT_L from '../../assets/appLogo/tarra-logotype-l.svg';
import tarraPM_D from '../../assets/appLogo/tarra-picturemark-d.svg';
import tarraLT_D from '../../assets/appLogo/tarra-logotype-d.svg';
import tarraH2hPM_L from '../../assets/appLogo/tarra-h2h-picturemark-l.svg';
import tarraH2hLT_L from '../../assets/appLogo/tarra-h2h-logotype-l.svg';
import tarraH2hPM_D from '../../assets/appLogo/tarra-h2h-picturemark-d.svg';
import tarraH2hLT_D from '../../assets/appLogo/tarra-h2h-logotype-d.svg';

let appLogo = {
  developer:{
    light:[developerPM_L, developerLT_L],
    dark:[developerPM_D, developerLT_D]
  },
  efiling:{
    light:[efilingPM_L, efilingLT_L],
    dark:[efilingPM_D, efilingLT_D]
  },
  eppt:{
    light:[epptPM_L, epptLT_L],
    dark:[epptPM_D, epptLT_D]
  },
  halona:{
    light:[halonaPM_L, halonaLT_L],
    dark:[halonaPM_D, halonaLT_D]
  },
  ebilling:{
    light:[ebillingPM_L, ebillingLT_L],
    dark:[ebillingPM_D, ebillingLT_D]
  },
  h2h:{
    light:[h2hPM_L, h2hLT_L],
    dark:[h2hPM_D, h2hLT_D]
  },
  tarra:{
    light:[tarraPM_L, tarraLT_L],
    dark:[tarraPM_D, tarraLT_D]
  },
  tarraH2h:{
    light:[tarraH2hPM_L, tarraH2hLT_L],
    dark:[tarraH2hPM_D, tarraH2hLT_D]
  }
};

class AppLogoStatic extends Component {
  render(){
    let { appName, light, subname, ...others } = this.props;
    let getSource = (idx) => {
      return appLogo[appName][light ? 'light' : 'dark'][idx]
    };

    return(
      <div 
        className={"mpk-app-logo-static mpk-layout align-center justify-center" + (light ? "light" : "")}
        {...others}
      >
        <img
          className="picture-mark"
          src={getSource(0)}
          alt="logo"
          />
        <div className="mpk-layout logo-type-container">
          <img
            className="logo-type"
            src={getSource(1)}
            alt="logo"
            />
           { subname ? (
              <div className="mpk-margin-N left mpk-font-size-S" style={{opacity:.64}}>{subname}</div>
            ) : (null)}
        </div>
      </div>
    )
  }
}

AppLogoStatic.propTypes = {
  appName:PropTypes.string.isRequired,
  light:PropTypes.bool
};

export default AppLogoStatic;