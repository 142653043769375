/**
 * Created by dwiargo on 2/15/18.
 */

import React, { Component } from 'react';
import './ContainerTransform.scss';

class ContainerTransform extends Component{
  render(){
    return(
      <div className={"mpk-container-transform "+this.props.className}>
        {this.props.children}
      </div>
    )
  }
}

export default ContainerTransform;