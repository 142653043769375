/**
 * Created by dwiargo on 3/6/18.
 */

import * as types from '../actions/actionTypes';

const initialState = {
  toasts:[],
  autohide:true
};

let toasts;

export default ((state = initialState, action = {}) => {
  switch (action.type){

    case types.TOAST_ADD:
      toasts = state.toasts.slice();
      toasts.push({
        text:action.text,
        action:action.action
      });
      return Object.assign({}, state, {
        toasts,
        autohide:action.autohide
      });

    case types.TOAST_DISMISS:
      [, ...toasts] = state.toasts;
      return Object.assign({}, state, {toasts})

    default:
      return state;
  }
})