import React, { useEffect } from "react";
import tableWrapper from "components/tableWrapper";
import t from "counterpart";
import * as service from "./service";
import * as balanceAccountService from "../BalanceAccount/service";
import { httpService } from "../../../../libs/react-mpk";
import iziToast from "izitoast";

const Refund = (props) => {
  useEffect(() => {
    props.tableActions.deleteSelectedDataItems();
  }, []);

  return (
    <div className="mpk-full height mpk-padding-N padding-all">
      {props.children}
    </div>
  );
};

export default tableWrapper((props, handle) => {
  let { userBalanceAccount } = props.tmpObject;

  const updateStatusRefund = (dataToPut) => {
    props.dialogActions.showConfirm(
      "Update Status Refund",
      "Apakah Anda Yakin Ingin Update Status Refund?",
      async (callback) => {
        try {
          await service.putRefundStatus({
            refundId: dataToPut,
          });
          props.tableActions.deleteSelectedDataItems();
          props.tableActions.reload();
          callback(false, "Berhasil Ubah Status Refund!");
        } catch (error) {
          callback(
            true,
            httpService.utils.parseErrorMessage(
              error,
              this.props.global.localeCode
            )
          );
        }
      },
      null,
      t.translate("word.yes"),
      t.translate("word.cancel")
    );
  };

  const downloadDetailTransfer = async (dataToPost) => {
    try {
      await service.downloadById({
        refundId: dataToPost,
      });
      iziToast.success({ message: "Permintaan Unduh Berhasil di Kirim" });
    } catch (error) {
      iziToast.error({ message: "Terjadi Kesalahan Saat Download File!" });
    }
  };

  return {
    bulkSelect: true,
    tableType: "refund",
    hint: {
      children: userBalanceAccount
        ? t
            .translate("routes.userBalance.transaction.hint.userRefundText")
            .replace("*name", userBalanceAccount.userInfo.username)
        : t.translate("routes.userBalance.transaction.hint.refundText"),
    },
    columns: [
      {
        label: t.translate("column.debitDateTime"),
        value: "debitDateTime",
        isSearchable: false,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.userBalanceAccount"),
        value: (d) => (
          <div>
            <div>{d.userBalanceAccount.accountNo}</div>
            <div className="mpk-font-size-S mpk-font-color-D3">
              {d.userBalanceAccount.username}
            </div>
          </div>
        ),
        valueName: "userBalanceAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.userBankAccount"),
        value: (d) => (
          <div>
            <div>{d.userBankAccount.accountNo}</div>
            <div className="mpk-font-size-S mpk-font-color-D3">
              {d.userBankAccount.accountName}
            </div>
          </div>
        ),
        valueName: "userBankAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.bankDestination"),
        value: "userBankAccount.bank.code",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.amount"),
        value: (d) => <div>{d.amount}</div>,
        valueName: "userBalanceAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.status"),
        value: (d) => <div>{d.statusMessage}</div>,
        valueName: "userBalanceAccount.statusMessage",
        type: "func",
        isSearchable: true,
        isSortable: false,
        show: true,
      },
    ],
    itemActions: [
      {
        label: "Edit Status",
        iconClassName: "mdi mdi-pencil",
        onClick: (item) => updateStatusRefund([item?.id]),
      },
      {
        label: "Download Detail Transfer",
        iconClassName: "mdi mdi-download",
        onClick: (item) => downloadDetailTransfer([item?.id]),
      },
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        let { userBalanceAccount } = props.tmpObject;
        try {
          let res = userBalanceAccount
            ? await balanceAccountService.getRefund(
                userBalanceAccount.accountInfo.id,
                params,
                onDataItem
              )
            : await service.getRefund(params, onDataItem);
          resolve({
            data: [],
            total: Number(res.headers["x-pagination-count"]),
          });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
  };
})(Refund);
