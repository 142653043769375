import Input from './Input';
import { utilsService } from 'services';
import t from 'counterpart';


const close = () => {
  window.dispatchEvent(new CustomEvent('pin', {detail: {eventType: 'close'}}));
}

const required = () => (new Promise((resolve, reject) => {
  utilsService.setReqOptionsPin(null);
  let listenerName = 'on-pin-data';
  window.dispatchEvent(new CustomEvent('pin', {detail: {eventType: 'required'}}))
  window.addEventListener(listenerName, e => {
    let { eventType, pin } = e.detail;
    window.removeEventListener(listenerName, () => {}, false);
    if (eventType === 'submit') {
      utilsService.setReqOptionsPin(pin);
      resolve();
    } else reject(new Error(t.translate('sentence.pin.cancelInput')));
  })
}))

export { 
  Input,
  required,
  close
}