/**
 * Created by dwiargo on 2/21/18.
 */

import React, { Component } from 'react';
import ErrorPage from 'libs/react-mpk/components/ErrorPage';
import queryString from 'query-string';
import { rdxConnect } from 'libs/react-mpk';

class Error extends Component{
  constructor(){
    super();
    this.state = {}
  }

  componentDidMount(){
    let parsedUrl = queryString.parseUrl(window.location.href);
    this.setState(parsedUrl.query);
  }

  render(){
    let { status, statusText, errorMessage, redirectUri } = this.state;
    let { tmpObject } = this.props;
    return tmpObject.asWidget ? (
      <div className="mpk-padding-N padding-all">
        {errorMessage}
        <span 
          className="mpk-link"
          onClick={() => {
            window.parent.postMessage({
              eventName: 'custom-event-ub',
              data: {
                type: 'on-get-new-access-token'
              }
            }, tmpObject.parentOrigin)
          }}
        >
          Try Reload
        </span>
      </div>
    ) : (
      <ErrorPage
        status={status}
        statusText={statusText}
        errorMessage={errorMessage}
        redirectUri={redirectUri}
        showRelogin={!this.props.tmpObject.asWidget}
      />
    )
  }
}

export default rdxConnect(Error);