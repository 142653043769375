/**
 * Created by dwiargo on 12/5/17.
 */

import counterpart from 'counterpart';

export default {
  init: (code, source) => {
    counterpart.registerTranslations(code, source)
  }
}