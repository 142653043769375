import React from 'react';
import Lightbox from 'react-images';
import { mpkEnv } from '../..';
import qs from 'query-string';

class Promo extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      open: false
    }
  }

  componentWillMount(){
    this.fetchData();
  }

  fetchData = async () => {
    let { host, baseUrl, clientId, channel } = mpkEnv.portal;
    try {
      let res = await fetch(`${host}${baseUrl}/api/promos/es/all?${qs.stringify({
        sort:1,
        sortBy:'datePublished',
        channels: channel
      })}`,
      {
        headers:{
          'x-client':clientId
        }
      })
      let data = await res.json();
      if(Array.isArray(data)){
        this.setState({data: data.map(d => (d.image)), open: true});
      }
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    let { data, open } = this.state;
    return( data.length > 0 ? (
      <Lightbox
        images={data}
        isOpen={open}
        onClose={() => this.setState({open:false})}
      />
      ) : null
    )
  }
}

export default Promo;