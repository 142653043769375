import React from "react";
import tableWrapper from "components/tableWrapper";
import t from "counterpart";
import * as service from "./service";
import * as balanceAccountService from "../BalanceAccount/service";

const Refunded = (props) => {
  return (
    <div className="mpk-full height mpk-padding-N padding-all">
      {props.children}
    </div>
  );
};

export default tableWrapper((props, handle) => {
  let { userBalanceAccount } = props.tmpObject;
  return {
    hint: {
      children: userBalanceAccount
        ? t
            .translate("routes.userBalance.transaction.hint.userRefundText")
            .replace("*name", userBalanceAccount.userInfo.username)
        : t.translate("routes.userBalance.transaction.hint.refundedText"),
    },
    columns: [
      {
        label: t.translate("column.debitDateTime"),
        value: "debitDateTime",
        isSearchable: false,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.userBalanceAccount"),
        value: (d) => (
          <div>
            <div>{d.userBalanceAccount.accountNo}</div>
            <div className="mpk-font-size-S mpk-font-color-D3">
              {d.userBalanceAccount.username}
            </div>
          </div>
        ),
        valueName: "userBalanceAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.userBankAccount"),
        value: (d) => (
          <div>
            <div>{d.userBankAccount.accountNo}</div>
            <div className="mpk-font-size-S mpk-font-color-D3">
              {d.userBankAccount.accountName}
            </div>
          </div>
        ),
        valueName: "userBankAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.bankDestination"),
        value: "userBankAccount.bank.code",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.amount"),
        value: (d) => <div>{d.amount}</div>,
        valueName: "userBalanceAccount.accountNo",
        type: "func",
        isSearchable: true,
        isSortable: true,
        show: true,
      },
      {
        label: t.translate("column.status"),
        value: (d) => <div>{d.statusMessage}</div>,
        valueName: "userBalanceAccount.statusMessage",
        type: "func",
        isSearchable: true,
        isSortable: false,
        show: true,
      },
    ],
    tableProps: {
      selectableRows: false,
    },
    itemActions: [
      // {
      //   label: "Edit Status",
      //   iconClassName: "mdi mdi-pencil",
      //   onClick: (item) => handle.showForm(true, item),
      // },
      // {
      //   label: "Download Detail Transfer",
      //   iconClassName: "mdi mdi-download",
      //   onClick: () => console.log("Download"),
      // },
    ],
    fetchData: (params, onDataItem) => {
      params.sortBy = "processDateTime";
      return new Promise(async (resolve, reject) => {
        let { userBalanceAccount } = props.tmpObject;
        try {
          let res = userBalanceAccount
            ? await balanceAccountService.getRefund(
                userBalanceAccount.accountInfo.id,
                params,
                onDataItem
              )
            : await service.getRefunded(params, onDataItem);
          resolve({
            data: [],
            total: Number(res.headers["x-pagination-count"]),
          });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
  };
})(Refunded);
