/**
 * Created by dwiargo on 12/4/17.
 */

import React, { Component } from 'react';
import MitraDJP from '../MitraDJP';
import { FontIcon } from 'react-md';
import env from '../../config/env';
import './SidebarFooter.scss';

class SidebarFooter extends Component {
  constructor(){
    super();
    this.state = {onClient: false}  
  }

  componentDidMount(){
    this.setState({onClient: true});
  }

  onLiveChat = () => {
    let { appInfo } = this.props.global
    window.liveChatWidget.create(callback => {
      callback(appInfo.name, true);
    })
  }

  render() {
    return (
      <div className="mpk-sidebar-footer mpk-padding-N right left">
        <div className="mpk-layout align-center justify-between">
          {/* env.theme === env.themes.PAJAKKU ? (
            <MenuButtonColumn
              adjusted={false}
              flat
              menuItems={supportItems.filter(d => (d.show)).map((d, i) => (
                <ListItem
                  key={`support-item-${i}`}
                  onClick={d.onClick}
                  leftIcon={
                    <FontIcon iconClassName={d.iconClassName}/>
                  }
                  primaryText={d.label}
                />
              ))}
              simplifiedMenu={false}
              anchor={{
                x: MenuButton.HorizontalAnchors.CENTER,
                y: MenuButton.VerticalAnchors.TOP,
              }}
              repositionOnScroll={false}
              iconClassName="mdi mdi-check-circle-outline"
            >
              <div className="mpk-font-size-S">
                {(() => {
                  switch(env.theme){
                    case env.themes.SOBATPAJAK:
                      return 'sobatpajak';
                    default:
                      return 'pajakku'
                  }
                })()}
              </div>
              <div className="mpk-link">Pusat Bantuan</div>
            </MenuButtonColumn>
          ) : (
            null
          )*/}
          {env.theme === env.themes.PAJAKKU ? (
            <div className="mpk-layout align-center">
              <FontIcon 
                iconClassName="mdi mdi-web"
              />
              <div className="mpk-margin-S margin-left">
                <div className="mpk-font-size-S" style={{lineHeight: 1}}>Visit</div>
                <a 
                  href="https://pajakku.com" target="_blank"
                  className="mpk-link"
                  style={{textDecoration: 'none'}}
                  rel="noopener noreferrer"
                >
                  pajakku.com
                </a>
              </div>
            </div>
          ) : null }
          <MitraDJP light/>
        </div>
      </div>
    )
  }
}

export default SidebarFooter;