/**
 * Created by dwiargo on 12/14/17.
 */

import React, { Component } from 'react';
import Translate from 'react-translate-component';
import Logo from '../Logo'
import Address from './Address';
import SendEmail from './SendEmail';
import './GeneralInfo.scss';

class GeneralInfo extends Component {
  render() {
    const links = [
      {
        label: "sentence.generalInfo.links.privacyPolicy"
      },
      {
        label: "sentence.generalInfo.links.termsOfService"
      },
      {
        label: "sentence.generalInfo.links.disclaimer"
      },
      {
        label: "sentence.generalInfo.links.guidelines"
      },
      {
        label: "sentence.generalInfo.links.help"
      },
      {
        label: "sentence.generalInfo.links.applications"
      },
    ];

    return (
      <div className="mpk-general-info mpk-layout align-start column-sm child-fill">
        <div className="mpk-layout column-sm">
          <div className="logo">
            <Logo light/>
          </div>
          <Address light/>
        </div>
        <div className="mpk-layout wrap flex column-xs">
          <div className="mpk-font-size-L link">
            { links.map((d, i) => (
              <div className="mpk-link-on-hover" key={i}>
                <Translate content={d.label}/>
              </div>
            ))}
          </div>
          <SendEmail/>
        </div>
      </div>
    )
  }
}

export default GeneralInfo;
