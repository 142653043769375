import React, { Component } from 'react';
import { FontIcon, LinearProgress } from 'react-md';
import './CurrentCompany.scss';
import t from 'counterpart';
import { rdxConnect } from '../../../../hoc';

class CurrentCompany extends Component{
  constructor(){
    super();
    this.state = {
      showSelectCompany:false,
      isLoading: false
    }
  }

  onReloadProfile = () => {
    let { onReloadProfile, toastActions } = this.props;
    this.setState({isLoading: true});
    onReloadProfile((isError, message) => {
      this.setState({isLoading: false});
      if(isError){
        toastActions.izi(
          t.translate('word.failed'),
          message,
          'warning'
        )
      } else {
        toastActions.izi(
          t.translate('word.success'),
          t.translate('sentence.company.successReload'),
          'success'
        )
      }
    })
  }

  render(){
    let {company} = this.props.auth;
    return(
      <div className="mpk-current-company mpk-padding-N padding-left padding-right">
        {company ? (
          <div className="mpk-layout align-start mpk-border solid bottom dark mpk-padding-N padding-top padding-bottom mpk-position relative">
            <div className="logo flex-none mpk-layout justify-center align-center mpk-margin-N right">
              {company.icon? (
                <img src={company.icon} role="presentation" alt={company.name}/>
              ) : null}
              <div className="default-img mpk-layout align-center justify-center">
                <FontIcon iconClassName="mdi mdi-square-inc"/>
              </div>
            </div>
            <div>
              <div className="mpk-margin-S margin-bottom">
                  <div className="mpk-font-size-M mpk-font-weight-M">
                    {company.name}
                  </div>
                  {/* <span className="mpk-font-size-S mpk-font-color-D3">{company.address}</span> */}
              </div>
              <div className="mpk-layout mpk-font-size-NS actions align-center">
                <div 
                  className="mpk-link" 
                  onClick={this.onReloadProfile}
                >
                  {t.translate('sentence.company.reloadProfile')}
                </div>
                <div 
                  className="mpk-link" 
                  onClick={() => {
                    window.open(`${this.props.userAccountUrl}/company/${company.id}/profile`)
                  }}
                >
                  {t.translate('sentence.company.showProfile')}
                </div>
              </div>
            </div>
            { this.state.isLoading ? <LinearProgress/> : null}
          </div>
        ) : null}
      </div>
    )
  }
}

CurrentCompany.defaultProps = {
  onReloadProfile: () => {},
  userAccountUrl: 'https://user.pajakku.com'
}

export default rdxConnect(CurrentCompany);