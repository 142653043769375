/**
 * Created by dwiargo on 11/30/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from 'react-md';
import Translate from 'react-translate-component';
import counterpart from 'counterpart';
import { isMobile } from 'react-device-detect';
import './Commandbar.scss';

class Commandbar extends Component{
  render(){
    let {translate, breadcrumbs} = this.props;
    const renderActions = this.props.actions.filter(d => {
      d.show = typeof d.show === 'boolean' ? d.show : true;
      return d.show;
    }).map((d, i) => {
      let _disabled = d.disabledFunc ? d.disabledFunc() : false;
      return (
        <Button
          icon primary key={i}
          iconClassName={d.iconClassName}
          tooltipLabel={translate ? counterpart.translate(d.label) : d.label}
          disabled={_disabled}
          onClick={() => {
            if(d.onClick) d.onClick();
          }}
          />
      )
    });

    const generateBreadcrumbs = breadcrumbs.map((b, i) => (
      <span key={`mpk-command-bar-breadcrumbs-${i}`} className="mpk-breadcrumb-item">{b}</span>
    ))

    const GetTitle = () => (
      <div className={breadcrumbs.length > 0 ? 'mpk-layout align-center' : ''}>
        { isMobile ? null : generateBreadcrumbs}
        <span className={breadcrumbs.length > 0 ? 'mpk-font-weight-B' : ''}>
          {translate ? (
              <Translate content={this.props.title}/>
            ) : (this.props.title)}
        </span>
      </div>
    )

    return(
      <div
        className="mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark"
        style={this.props.style}
      >
        {this.props.leftCorner ? (
          <div className="left-corner">{this.props.leftCorner}</div>
        ) : (null)}
        <div className="mpk-font-size-M flex mpk-layout align-center mpk-content mpk-no-wrap">
          {this.props.isLoading ? (
            <div><CircularProgress id="command-bar-loader" scale={.72}/></div>
          ) : (<GetTitle/>)}
        </div>
        {this.props.actions.length > 0 ? (
          <div className="actions mpk-layout">{renderActions}</div>
        ) : (null)}
        {this.props.rightCorner ? (
          <div className="right-corner">{this.props.rightCorner}</div>
        ) : (null)}
      </div>
    )
  }
}

Commandbar.defaultProps = {
  style:{},
  actions:[],
  breadcrumbs:[]
};

Commandbar.propTypes = {
  title:PropTypes.any,
  style:PropTypes.object,
  isLoading:PropTypes.bool,
  actions:PropTypes.arrayOf(PropTypes.shape({
    label:PropTypes.string.isRequired,
    iconClassName:PropTypes.string.isRequired,
    onClick:PropTypes.func,
    disabledFunc:PropTypes.func
  })),
  rightCorner:PropTypes.element,
  translate:PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string) // SEPARATED BY |
};

export default Commandbar;