/**
 * Created by dwiargo on 1/24/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AppLogo.scss';

import developerPM_L from '../../assets/appLogo/developer-picturemark-l.svg';
import developerLT_L from '../../assets/appLogo/developer-logotype-l.svg';
import developerPM_D from '../../assets/appLogo/developer-picturemark-d.svg';
import developerLT_D from '../../assets/appLogo/developer-logotype-d.svg';
import efilingPM_L from '../../assets/appLogo/efiling-picturemark-l.svg';
import efilingLT_L from '../../assets/appLogo/efiling-logotype-l.svg';
import efilingPM_D from '../../assets/appLogo/efiling-picturemark-d.svg';
import efilingLT_D from '../../assets/appLogo/efiling-logotype-d.svg';
import epptPM_L from '../../assets/appLogo/eppt-picturemark-l.svg';
import epptLT_L from '../../assets/appLogo/eppt-logotype-l.svg';
import epptPM_D from '../../assets/appLogo/eppt-picturemark-d.svg';
import epptLT_D from '../../assets/appLogo/eppt-logotype-d.svg';
import halonaPM_L from '../../assets/appLogo/halona-picturemark-l.svg';
import halonaLT_L from '../../assets/appLogo/halona-logotype-l.svg';
import halonaPM_D from '../../assets/appLogo/halona-picturemark-d.svg';
import halonaLT_D from '../../assets/appLogo/halona-logotype-d.svg';
import ebillingPM_L from '../../assets/appLogo/ebilling-picturemark-l.svg';
import ebillingLT_L from '../../assets/appLogo/ebilling-logotype-l.svg';
import ebillingPM_D from '../../assets/appLogo/ebilling-picturemark-d.svg';
import ebillingLT_D from '../../assets/appLogo/ebilling-logotype-d.svg';
import h2hPM_L from '../../assets/appLogo/h2h-picturemark-l.svg';
import h2hLT_L from '../../assets/appLogo/h2h-logotype-l.svg';
import h2hPM_D from '../../assets/appLogo/h2h-picturemark-d.svg';
import h2hLT_D from '../../assets/appLogo/h2h-logotype-d.svg';
import tarraPM_L from '../../assets/appLogo/tarra-picturemark-l.svg';
import tarraLT_L from '../../assets/appLogo/tarra-logotype-l.svg';
import tarraPM_D from '../../assets/appLogo/tarra-picturemark-d.svg';
import tarraLT_D from '../../assets/appLogo/tarra-logotype-d.svg';
import tarraH2hPM_L from '../../assets/appLogo/tarra-h2h-picturemark-l.svg';
import tarraH2hLT_L from '../../assets/appLogo/tarra-h2h-logotype-l.svg';
import tarraH2hPM_D from '../../assets/appLogo/tarra-h2h-picturemark-d.svg';
import tarraH2hLT_D from '../../assets/appLogo/tarra-h2h-logotype-d.svg';
import ebupotPM_L from '../../assets/appLogo/ebupot-picturemark-l.svg';
import ebupotLT_L from '../../assets/appLogo/ebupot-logotype-l.svg';
import ebupotPM_D from '../../assets/appLogo/ebupot-picturemark-d.svg';
import ebupotLT_D from '../../assets/appLogo/ebupot-logotype-d.svg';
import payjakPM_L from '../../assets/appLogo/payjak-picturemark-l.svg';
import payjakLT_L from '../../assets/appLogo/payjak-logotype-l.svg';
import payjakPM_D from '../../assets/appLogo/payjak-picturemark-d.svg';
import payjakLT_D from '../../assets/appLogo/payjak-logotype-d.svg';
import mpnPM_L from '../../assets/appLogo/mpn-picturemark-l.svg';
import mpnLT_L from '../../assets/appLogo/mpn-logotype-l.svg';
import mpnPM_D from '../../assets/appLogo/mpn-picturemark-d.svg';
import mpnLT_D from '../../assets/appLogo/mpn-logotype-d.svg';
// import mpnPM_L from '../../assets/appLogo/mpn-logo.png';
// import mpnLT_L from '../../assets/appLogo/mpn-logo.png';
// import mpnPM_D from '../../assets/appLogo/mpn-logo.png';
// import mpnLT_D from '../../assets/appLogo/mpn-logo.png';


let appLogo = {
  developer:{
    light:[developerPM_L, developerLT_L],
    dark:[developerPM_D, developerLT_D]
  },
  efiling:{
    light:[efilingPM_L, efilingLT_L],
    dark:[efilingPM_D, efilingLT_D]
  },
  eppt:{
    light:[epptPM_L, epptLT_L],
    dark:[epptPM_D, epptLT_D]
  },
  halona:{
    light:[halonaPM_L, halonaLT_L],
    dark:[halonaPM_D, halonaLT_D]
  },
  ebilling:{
    light:[ebillingPM_L, ebillingLT_L],
    dark:[ebillingPM_D, ebillingLT_D]
  },
  h2h:{
    light:[h2hPM_L, h2hLT_L],
    dark:[h2hPM_D, h2hLT_D]
  },
  tarra:{
    light:[tarraPM_L, tarraLT_L],
    dark:[tarraPM_D, tarraLT_D]
  },
  tarraH2h:{
    light:[tarraH2hPM_L, tarraH2hLT_L],
    dark:[tarraH2hPM_D, tarraH2hLT_D]
  },
  ebupot:{
    light:[ebupotPM_L, ebupotLT_L],
    dark:[ebupotPM_D, ebupotLT_D]
  },
  payjak:{
    light:[payjakPM_L, payjakLT_L],
    dark:[payjakPM_D, payjakLT_D]
  },
  mpn:{
    light:[mpnPM_L, mpnLT_L],
    dark:[mpnPM_D, mpnLT_D]
  }
};

class AppLogo extends Component {
  render(){
    let { appName, light, style={}, subname, className='', logoTypeOnly=false} = this.props;
    let getSource = (idx) => {
      return appLogo[appName][light ? 'light' : 'dark'][idx]
    };

    return logoTypeOnly ? (
      <img
        style={style}
        src={getSource(1)}
        alt="logo"
      />
    ) : (
      <div 
        className={`mpk-app-logo mpk-layout align-center ${light ? 'light' : ''} ${className}`}
        style={style}
      >
        <img
          className="picture-mark"
          src={getSource(0)}
          alt="logo"
          />
        <div className="mpk-layout align-center">
          <img
            className="logo-type"
            src={getSource(1)}
            alt="logo"
            />
           { subname ? (
              <div className="mpk-margin-N left mpk-font-size-S" style={{opacity:.64}}>{subname}</div>
            ) : (null)}
        </div>
      </div>
    )
  }
}

AppLogo.propTypes = {
  appName:PropTypes.string.isRequired,
  light:PropTypes.bool
};

export default AppLogo;