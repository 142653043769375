import React from 'react';
import { Button, List, ListItem, FontIcon, Paper, Divider } from 'react-md';
import Draggable from 'react-draggable';
import Script from 'react-load-script';
import './index.scss';

class MenuItems extends React.Component{
  state = {clientHeight: 0}
  componentDidMount(){
    const element = document.getElementById('support-mpk-menu-items');
    this.setState({clientHeight: element.clientHeight});
  }

  render(){
    let { enabledForum, enabledLiveChat, appName, forumUri, kbs } = this.props;
    return(
      <div>
        <Script url="https://kbs.pajakku.com/static/libs/kbs-widget.min.js"/>
        <Script url="https://webchat.pajakku.com/static/libs/live-chat-widget.js"/>
        
        <Paper 
          className="menu-items" id="support-mpk-menu-items"
          style={{
            top: this.state.clientHeight*-1,
            opacity: 1
          }}
        >
          <div className="header">
            <FontIcon iconClassName="mdi mdi-check-circle-outline"/>
            Pusat Bantuan
          </div>
          <List style={{padding: 0}}>
            { kbs.length > 0 ? <Divider/> : null }
            { kbs.map(d => (
              <ListItem
                key={`${d.type}-${d.code}`}
                leftIcon={<FontIcon iconClassName={ d.icon || (d.type === 'documentation' ? `mdi mdi-file-document` : 'mdi mdi-alert')}/>}
                primaryText={d.label}
                onClick={() => {
                  if(window.kbsWidget) window.kbsWidget[d.type](d.code);
                }}
              />
            ))}
            { enabledForum || enabledLiveChat ? <Divider/> : null }
            { enabledForum || enabledLiveChat ? (
              <div className="info">
                Gunakan layanan interaktif pajakku, tim support pajakku siap membantu Anda
              </div>
            ): null }
            { enabledForum ? (
              <ListItem
                leftIcon={<FontIcon iconClassName="mdi mdi-message"/>}
                primaryText="Forum"
                onClick={() => window.open(forumUri, '_blank')}
              />
            ) : null}
            { enabledLiveChat ? (
              <ListItem
                leftIcon={<FontIcon iconClassName="mdi mdi-forum"/>}
                primaryText="Live Chat"
                onClick={() => {
                  if(window.liveChatWidget) window.liveChatWidget.create(callback => {
                    callback(appName, true);
                  })
                }}
              />
            ) : null }
            <Divider/>
            <ListItem
              leftIcon={<FontIcon iconClassName="mdi mdi-phone"/>}
              primaryText="Info Kontak"
              onClick={() => {
                const w = 560;
                const h = 640;
                const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
                const left = ((width / 2) - (w / 2)) + dualScreenLeft;
                const top = ((height / 2) - (h / 2)) + dualScreenTop;   
                window.open(`https://pajakku.com/contact`, 'Contact Pajakku', 'width='+w+', height='+h+', top='+top+',left='+left)
              }}
            />
            <Divider/>
            <ListItem
              leftIcon={<FontIcon iconClassName="mdi mdi-close"/>}
              primaryText="Sembunyikan"
              onClick={this.props.onClose}
            />
          </List>
        </Paper>
      </div>
    )
  }
}

class SupportMpk extends React.Component{
  constructor(){
    super();
    this.state = {
      isOver: false,
      showMenu: false,
      hitTrigger: false,
      hasMove: false,
      isFirst: true,
      lastX: null,
      lastY: null
    }
  }

  handleShowMenu = () => {
    let { showMenu, hasMove, isFirst } = this.state;
    if(isFirst || !hasMove)
      this.setState({showMenu: !showMenu, hasMove: false, isFirst: false});
  }

  handleMovement = (e, data) => {
    let { lastX, lastY } = this;
    let hasMove = lastX === null && lastY === null ? false : Math.abs(lastX - data.lastX) + Math.abs(lastY - data.lastY) > 10 ;
    if(!hasMove) this.setState({showMenu: true})
  }
  
  render(){
    let { ...menuProps } = this.props
    return(
      <Draggable
        // axis="x"
        handle=".handle"
        defaultPosition={{x: 0, y: 0}}
        position={null}
        onStart={(e, data) => {
          this.lastX = data.lastX;
          this.lastY = data.lastY;
        }}
        onStop={this.handleMovement}
        // grid={[48, 48]}
        scale={1}
      >
        <div className="handle support-mpk">
          <Button 
            raised secondary swapTheming
            className="fab"
            onMouseOver={() => this.setState({isOver: true})}
            onMouseOut={() => this.setState({isOver: false})}
            icon iconClassName={`mdi ${this.state.isOver ? 'mdi-cursor-move' : 'mdi-assistant'}`}
            style={{width: 56, height: 56}}
          />
          { this.state.showMenu ? 
            <MenuItems 
              onClose={() => this.setState({showMenu: false})}
              {...menuProps}
            /> 
          : null }
        </div>
      </Draggable>
    )
  }
}

SupportMpk.defaultProps = {
  enabledLiveChat: false,
  enabledForum: true,
  appName: 'support-launcher',
  forumUri: 'https://pajakku.com/forum-categories',
  kbs: []
}

export default SupportMpk;